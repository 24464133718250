import React, { useState } from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import rolesModel from '../../models/roles.model';
import methodModel from '../../components/validations/Validations';
import CommonDeleteModal from '../../models/delete.modal';
import CommonActiveModal from '../../models/active-deactive.modal';
import environment from '../../environment';
import * as CryptoJS from 'crypto-js'

const Html = ({
    view,
    edit,
    reset,
    tab,
    statusChange,
    pageChange,
    deleteItem,
    filters,
    loaging,
    data,
    role,
    ShowActiveModal,
    setShowActiveModal,
    ShowDeleteModal,
    setShowDeleteModal,
    sorting,
    total = { total }
}) => {

    const [DeleteId, setDeleteId] = useState("");
    const Delete = () => {
        deleteItem(DeleteId);
    }

    const [StatusData, setStatusData] = useState({});
    const StatusCh = () => {
        statusChange(StatusData);
    }

    function decrypt(encryptedValue) {
        const bytes = CryptoJS?.AES?.decrypt(encryptedValue, environment?.secretKey);
        console.log(bytes, "check");
        return bytes?.toString(CryptoJS.enc.Utf8); // Moved return to the same line
    }

    return (
        <Layout>
            <CommonDeleteModal show={ShowDeleteModal} setShow={setShowDeleteModal} confirm={Delete} />
            <CommonActiveModal show={ShowActiveModal} setShow={setShowActiveModal} confirm={StatusCh} status={StatusData.status} />
            <div className="d-flex justify-content-between align-items-center">
                <h3 className="hedding">
                    {role ? rolesModel.name(role) : 'Customers'}
                </h3>

                <article className="d-flex filterFlex phView">

                    {filters.status || filters.role ? <>
                        <a className="btn btn-danger text-white mr-2" onClick={e => reset()}>
                            Reset
                        </a>
                    </> : <></>}

                </article>


            </div>


            {tab == 'grid' ? <>
                <div className="cardList">
                    <div className='row'>
                        {!loaging && data && data.map((itm, i) => {
                            return <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4'>
                                <div className='new_cards'>
                                    <div className='user_card'>
                                        <div className='user_detail' onClick={e => view(decrypt(itm.cpprofile_id), (itm?._id))}>
                                            <img src={methodModel.userImg(itm.image)} className="user_imgs" />

                                            <div onClick={e => view(decrypt(itm.cpprofile_id), itm?._id)} className='user_name'>
                                                <h4 className='user '>
                                                    {itm.fullName}
                                                </h4>
                                                <p className='user_info'>
                                                    {itm.email}
                                                </p>
                                            </div>
                                        </div>

                                        <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                            <span className='contract'>
                                                {itm.status}
                                            </span>
                                        </div>
                                    </div>


                                    <div className='user_proff user_proff1'>
                                        <div className='id_name'>
                                            <ul className='user_list'>
                                                <li className='list_name'>
                                                    <a className='id'>
                                                        Role
                                                    </a>
                                                </li>
                                                <li className='list_name'>
                                                    <a className='id'>
                                                        Phone number
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='detail_list'>
                                            <ul className='user_list'>
                                                <li className='list_names'>
                                                    <a className='id_name' onClick={e => edit(itm.id)}>
                                                        {itm.role?.name}

                                                    </a>
                                                </li>
                                                <li className='list_names'>
                                                    <a className='id_name' onClick={e => edit(itm.id)}>
                                                        <span className='call_icon'></span>
                                                        {itm.mobileNo ?
                                                            <>
                                                                <i class="fa fa-phone" aria-hidden="true"></i>
                                                                {itm.dialCode} {itm.mobileNo}
                                                            </>
                                                            :
                                                            ''
                                                        }
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}

                    </div>

                </div>
            </> : <>

                <div className="table-responsive table_section">

                    <table class="table">
                        <thead className='table_head'>
                            <tr className='heading_row'>
                                <th scope="col" className='table_data pointer' onClick={(e) =>
                                    filters?.sortBy == "full_name asc"
                                        ? sorting("full_name desc", "desc")
                                        : sorting("full_name asc", "asc")
                                }>Name  <span className='pointer'>{filters?.sortBy === "full_name asc" ? "↑" : "↓"}</span></th>
                                <th scope="col" className='table_data pointer' onClick={(e) =>
                                    filters?.sortBy == "email asc"
                                        ? sorting("email desc", "desc")
                                        : sorting("email asc", "asc")
                                }>Email  <span className='pointer'>{filters?.sortBy === "email asc" ? "↑" : "↓"}</span></th>
                                <th scope="col" className='table_data pointer' onClick={(e) =>
                                    filters?.sortBy == "createdAt asc"
                                        ? sorting("createdAt desc", "desc")
                                        : sorting("createdAt asc", "asc")
                                }>Mobile Number <span className='pointer'> {filters?.sortBy === "createdAt asc" ? "↑" : "↓"}</span></th>
                                {/* <th scope="col" className='table_data' onClick={(e) =>
                                        filters?.sortBy == "updatedAt asc"
                                            ? sorting("updatedAt desc", "desc")
                                            : sorting("updatedAt asc", "asc")
                                    }>Last Modified   {filters?.sortBy === "updatedAt asc" ? "↑" : "↓"}</th> */}
                                {/* <th scope="col" className='table_data'>Actions</th> */}

                            </tr>
                        </thead>
                        <tbody>
                            {!loaging && data && data.map((itm, i) => {
                                return <tr className='data_row'>


                                    <td className='table_dats'
                                    // onClick={()=>Navigate.push(`userdetail/${itm.id}`)}
                                    >

                                        <div className='user_detail'>
                                            <img src={methodModel.userImg(itm.image)} className="user_imgs" />
                                            <div onClick={e => view(decrypt(itm.cpprofile_id), itm?._id)} className='user_name'>
                                                <h4 className='user namefull'>
                                                    {itm.fullName}
                                                </h4>
                                            </div>
                                        </div></td>
                                    <td className='table_dats' style={{ textTransform: "lowercase" }}>{itm.email}</td>
                                    <td className='table_dats'>{itm?.mobileNo || '--'}</td>
                                    {/* <td className='table_dats'>{datepipeModel.date(itm.updatedAt)}</td> */}

                                </tr>

                            })
                            }
                        </tbody>
                    </table>

                    {!loaging && data?.length == 0 ? <div className="py-3 text-center">No Data</div> : <></>}

                </div>

            </>}






            {
                !loaging && total > filters.count ? <div className='paginationWrapper'>
                    <span>Show {filters.count} from {total}  Users</span>
                    <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : <></>
            }

            {loaging ? <div className="text-center py-4">
                <img src="/assets/img/loader.gif" className="pageLoader" />
            </div> : <></>}
        </Layout>
    );
};

export default Html;
