import React, { useState, useEffect } from "react";
import ApiClient from "../../apimethods/apiClient";
import "./home.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
} from "react-router-dom";
import {
  Accordion,
} from "react-bootstrap";
import WebsiteHeader from "../../components/global/websiteheader/WebsiteHeader";
import { login_success } from "../../redux/actions/user";
import Footer from "../../components/global/footer/Footer";

const Home = () => {
  const user = useSelector((state) => state.user);
  const websitedetails = useSelector((state) => state.website);
  const [cms, setcms] = useState();
  const [faq, setfaq] = useState();
  const history = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    getCMS();
    getFAQ();
  }, []);

  const getCMS = () => {
    ApiClient.get(`cms/listing`).then((res) => {
      if (res.success) {
        setcms(res.data);
      }
    });
  };

  const gallaryData = () => {
    ApiClient.get(`user/profile`, { id: user._id }).then(res => {
      if (res.success) {
        let data = { ...user, ...res.data }
        dispatch(login_success(data))
      }
    })
  };

  useEffect(() => {
    if (user?._id) {
      gallaryData()
    }
  }, [])

  const getFAQ = () => {
    ApiClient.get(`faq/listing?page=1&count=6`).then((res) => {
      if (res.success) {
        setfaq(res.data);
      }
    });
  };

  return (
    <>
      <div className="homepage">


        <section className="hero-image">
          <WebsiteHeader />
          <div className="banner-section">

            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6">
                  <div className="banner-inner">
                    <h1 className="text-left marginbm60">
                      Start accepting {" "}
                      <span className="d-block "><span className="yellow-color"> payments </span>in 3   </span>
                      minutes
                    </h1>
                    <p className="marginbm60">
                      Integrate xyz payment gateway without any technical knowledge.
                    </p>

                    <div className="d-flex  flex-wrap align-items-center marginbm60">
                      <button className="btn dark-btn mr-3" onClick={() => history('/signup')}>Get Started</button>
                      <button className="btn black-btn " onClick={() => history('/contactus')}>Contact Sales</button>
                      {/* <button className="btn black-btn mr-3" onClick={() => open()} > Connect a bank account</button> */}
                    </div>

                    <div className="rating_reviews d-flex">
                      <h2>4.7</h2>
                      <span className="star_icon">
                        <p>top rated on trustpilot</p>
                        <p>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </p>
                      </span>
                    </div>

                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-5 col-lg-6 col-xl-6">
                  <div className="banner-inner">
                    <img src="/assets/img/cardall.png" alt="cards" />
                  </div>
                </div>


              </div>
            </div>
          </div>

        </section>







        <section className="digital-transaction position-relative">

          <div className="banking-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-10 mx-auto">
                  <div className="inner_hero_img">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div className="one_cloumn_icon">
                          <div className="icon_tick">
                            <img src="/assets/img/h3.png" className="img-fluid" />

                          </div>
                          <div className="icon_inners">
                            <span>code free setup</span>
                            <p>One click integration </p>

                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div className="one_cloumn_icon">
                          <div className="icon_tick">
                            <img src="/assets/img/h2.png" className="img-fluid" />

                          </div>
                          <div className="icon_inners">
                            <span>Lowest Price</span>
                            <p>1% transaction fee, lowest in Industry </p>

                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div className="one_cloumn_icon">
                          <div className="icon_tick">
                            <img src="/assets/img/h1.png" className="img-fluid" />

                          </div>
                          <div className="icon_inners">
                            <span>Global withdrawals</span>
                            <p>Instant transfer to your bank account </p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* integrated section */}

          <div className="container">
            <div className="row">
              <div className="text-center payment_text">
                <h2>Accepting payments should  <br></br> not be hard</h2>
                <p>Accept payment using invoice, quick checkout, API’s and  payment buttons</p>
              </div>
            </div>
            <div className="row align-items-center section-inner-padding">
              <div className="col-lg-6">
                <div className="digital-trans-img">
                  <img src="/assets/img/digital-one.png" alt="tracker" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="digital-trans-inner">
                  <h2>Be in complete control over your money</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,
                  </p>
                </div>
              </div>
            </div>


          </div>
        </section>



        {/* Digital Transaction */}
        <section className="digital-transaction section-padding pt-0 pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="transc-title">
                  <h2>Super convenient Digital Transactions</h2>
                  <p className="mb-0">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <p>
                    {" "}
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="digital-transaction section-padding pt-0">
          <div className="container">
            <div className="row ">
              <div className="col-lg-6">
                <div className="transc-inner">
                  <ul>
                    <li className="active-transaction">
                      <h6>
                        {" "}
                        <img src="/assets/img/trans-one.svg" alt="" />
                        Income and expenses tracker{" "}
                      </h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                      </p>
                    </li>
                    <li>
                      <h6>
                        {" "}
                        <img src="/assets/img/trans-two.svg" alt="" />
                        Automated invoicing{" "}
                      </h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                      </p>
                    </li>
                    <li>
                      <h6>
                        {" "}
                        <img src="/assets/img/trans-three.svg" alt="" />
                        Crypto connection{" "}
                      </h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="transc-img">
                  <img src="/assets/img/tracker.png" alt="tracker" />
                </div>
              </div>
            </div>

            <div className="text-center issue-setup">
              <h2>Having issues with integration and setup?</h2>
              <button className="btn  dark-btn"> Let us Know</button>
            </div>
          </div>
        </section>




        {/* Custom Products Banner */}
        <section className="custom-products section-padding">
          <div className="container-fluid main-container">
            <div className="container">
              <div className="mobile-img-section">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="custom-product-content">
                      <h3>
                        <span className="yellow_color">
                          Custom-tailored product suites for{" "}
                        </span>
                        restaurants, retail,{" "}
                        <span className="yellow_color">and </span>beauty
                        businesses.
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-6 pe-0 img-right">
                    <div className="mobile-img">
                      <img src="/assets/img/custom-banner.png" alt="benefit" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonial Section */}
        <section className="testimonial-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="testimonial-title">
                  <h2>Trust the industry leaders</h2>
                  <p className="">
                    See what are users are saying about our services and support
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="highlighted-title">
                  <p></p>
                  <h4>Testimonial</h4>
                </div>
                <div className="testimonial-inner">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="testimonial-img">
                        <img src="/assets/img/man.png" alt="man" />
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <div className="testimonial-content">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                        </p>
                        <span>Acholo Aaron, CEO @ Pydelab</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="benefits-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="benefits-inner">
                  <h2>
                    Sell Anywhere easily,
                    <span className="d-block">quickly & seamlessly.</span>
                  </h2>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="bg-inner-img">
                        <img src="/assets/img/benefit-one.png" alt="benefit" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="bg-inner-img">
                        <img src="/assets/img/benefit-two.png" alt="benefit" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="bg-inner-img">
                        <img src="/assets/img/benefit-three.png" alt="benefit" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="optimize-operations">
                  <div className="optimize-title">
                    <h2>
                      Optimize your{" "}
                      <span className="d-block yellow_color">Operations.</span>
                    </h2>
                    <p>
                      Manage and streamline operations across multiple
                      locations, sales channels, and employees to improve
                      efficiency and your bottom line.
                    </p>
                  </div>
                  <ul className="business-goal">
                    <li>
                      <p>YOUR BUSINESS GOAL</p>
                      <p>HOW TO SOLVE IT WITH LUX FINANCIALS</p>
                    </li>
                  </ul>
                  <ul className="goals-points">
                    <li>
                      <h6>1. Diversify revenue</h6>
                      <div className="benefits-listing equal-margin">
                        <p>
                          Diversify your revenue by{" "}
                          <span className="yellow_color">
                            opening up new streams.
                          </span>
                        </p>
                        <p>
                          Track profit margins with{" "}
                          <span className="yellow_color">
                            inventory management
                          </span>{" "}
                          and reporting capabilities.
                        </p>
                        <p>
                          Integrate orders,{" "}
                          <span className="yellow_color">items,</span> and
                          inventory from your POS to <span>your website.</span>
                        </p>
                      </div>
                    </li>
                    <li>
                      <h6>2. Reach customers</h6>
                      <div className="benefits-listing">
                        <p>
                          Increase{" "}
                          <span className="yellow_color">customer loyalty</span>{" "}
                          and value with centralized{" "}
                          <span className="yellow_color">
                            customer data and insights
                          </span>
                        </p>
                      </div>
                    </li>
                    <li>
                      <h6>3. Advanced reporting</h6>
                      <div className="benefits-listing">
                        <p>
                          Get{" "}
                          <span className="yellow_color"> powerful data</span>{" "}
                          to make confident decisions.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Pro-support section */}
        <section className="cta-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="cta-section-inner">
                  <h2>Get Pro Support</h2>
                  <p>
                    We’re here to help. If you need assistance switching from
                    your current solution,have any questions about Lux
                    Financials is right for your business, or want support
                    getting started, please talk to a member of our team.
                  </p>
                  <a href="#" className="black-btn">
                    Contact Sales
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="faq_wrapper section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="faq_text">
                  <h2>
                    Frequently Asked <br></br>
                    Questions ?
                  </h2>
                  <p className="mt-3 text-white ">
                    For more information, please visit the{" "}
                    <span className="lblue d-block pb-0 m-0">Help Center.</span>
                    also find all faq’s on our faq’s page
                  </p>

                  <button
                    className="btn dark-btn mt-3"
                    onClick={(e) => {
                      history(`/faq`);
                    }}
                  >
                    See more
                  </button>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="faq_accordian">
                  <Accordion>
                    {faq &&
                      faq.map((item, index) => {
                        return (
                          <Accordion.Item eventKey={index}>
                            <Accordion.Header>
                              {item?.question}
                            </Accordion.Header>
                            <Accordion.Body
                              close
                              dangerouslySetInnerHTML={{ __html: item?.answer }}
                            >
                              {/* {item?.answer} */}
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Footer Section */}
        <Footer />
      </div>


    </>
  );
};

export default Home;