import { useEffect, useState } from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import Header from "../../components/global/layout/Header";
import Footer from "../../components/global/footer/Footer";
import ApiClient from "../../apimethods/apiClient";
import methodModel from "../../components/validations/Validations";
import loader from "../../components/loader/loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Whislist = ({}) => {
  const user = useSelector((state) => state.user);
  const [filters, setfilters] = useState({
    page: 1,
    count: 12,
    search: "",
    user_id: user?._id,
  });
  const [data, setData] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    if (user?.id) {
      allData();
    }
  }, []);

  const allData = () => {
    loader(true);
    let payload = {
      ...filters,
      page: 1,
      user_id: user?.id,
    };
    ApiClient.get(`wishlist`, payload).then((res) => {
      loader(false);
      if (res?.success) {
        setData(res?.data);
      }
    });
  };

  // const addTocart = (itm_id) => {
  //   if (user?.loggedIn) {
  //     let payload = {
  //       product_id: itm_id,
  //       merchant_id: localStorage?.getItem("merchant_id") || merchantID  ,
  //     };
  //     ApiClient.post(`cart/add-item`, payload).then((res) => {
  //       if (res?.success) {
  //       history("/cart")       
  //       }
  //     });
  //   } else {
  //     document.getElementById("loginModal1").click();
  //   }
  // };
 const removefromWislist =(prdt_id)=>{
  let payload = {
    type: "product",
    product_id: prdt_id,
  };
  ApiClient.post(`wishlist`, payload).then((res) => {
    if (res?.success) {
      allData(); 
    }
  });
 }
  

  return (
    <>
      <Header />
      <div className="top-padding">
        <div className="container">
          <div className="wishlistcard">
            <h3 className="wishheader">
              <i class="fa fa-heart mr-2 text-danger" aria-hidden="true"></i>My
              Wishlist
            </h3>
            <div className="d-flex gap-2 flex-wrap">
            {data.map((data) => (
              <div className="wisDiv">
                <div className="imgDiv">
                  <img
                    src={methodModel.noImg(data?.product_images?.[0])}
                    className="wistimg"
                  />
                </div>
                <div className="w-100">
                  <p>
                    {" "}
                    <span className="wishdata">{data?.product_name}</span>
                  </p>
                  <p>
                    {" "}
                    <span className="wishdata price">
                      ${data?.product_price}
                    </span>
                  </p>
                  
                  <div className="d-flex justify-content-end">
                  <button onClick={()=>{removefromWislist(data?.product_id)}}
                      class="btn dark-btn grayDl wishbtn mr-1"
                     
                    >
                      Remove
                    </button>
                    {/* <button
                      class="btn dark-btn wishbtn"
                      onClick={() => addTocart(data?.product_id)}
                    >
                      Add to Cart
                    </button> */}
                  </div>
                </div>
              </div>
            ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Whislist;
