import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import environment from "../../../environment";
import { Link } from "react-router-dom";
import "../../global/style.scss";
import methodModel from "../../validations/Validations";
import { login_success, logout, toggleMode } from "../../../redux/actions/user";
import ApiClient from "../../../apimethods/apiClient";

const WebsiteHeader = () => {
  const [token, setToken] = useState("");
  const websitedetails = useSelector((state) => state.website);
  const user = useSelector((state) => state.user);
  const mode = useSelector((state) => state.user);
  const history = useNavigate();
  const dispatch = useDispatch();
  const [Pages, setPages] = useState([]);
  const { slug } = useParams()
  const LocalSlug = localStorage.getItem("merSlug")
  const Token = localStorage.getItem("token")
  console.log(slug, "slug")
  console.log(LocalSlug, "LocalSlug")


  useEffect(() => {
    if (user?._id) {
      gallaryData();
    }
  }, []);

  const gallaryData = () => {
    ApiClient.get(`user/profile`, { id: user._id }).then((res) => {
      if (res.success) {
        let data = { ...user, ...res.data };
        dispatch(login_success(data));
      }
    });
  };

  const handleClick = () => {
    if (localStorage.getItem("token")) {
      ApiClient.get("mastercard/connect-url").then((res) => {
        window.open(res?.data);
      });
    }
  };

  const handleModeChange = () => {
    dispatch(toggleMode());
  };

  const Logout = () => {
    dispatch(logout());
    localStorage.removeItem("persist:admin-app");
    localStorage.removeItem("token");
    localStorage.removeItem("userid")
    if (user?.role?.name == "Customer" && localStorage?.getItem("merSlug")) {
      history(`/home/merchant/${localStorage?.getItem("merSlug")}`)

    }
    else {
      history("/home");
    }

  };

  const GetWebsitePages = () => {
    ApiClient.get(
      `cms/listing?search=&status=&category=657afeaed8d855aee5289478`
    ).then((res) => {
      if (res.success) {
        setPages(res?.data);
      }
    });
  };
  useEffect(() => {
    GetWebsitePages();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      ApiClient.post("plaid/create-link", {}).then((res) => {
        setToken(res?.data);
      });
    }
  }, []);


  return (
    <>
      <header className="dark-header  ">
        <div className="container-fluid">
          <div className="row align-items-start">
            <div className="col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3">
              <div className="header-logo">
                <a href="#">
                  <img
                    className="img-fluid"
                    src={
                      websitedetails?.logo
                        ? `${environment.imageapi}img/${websitedetails?.logo}`
                        : "assets/img/logo-dark.png"
                    }
                    onClick={(e) => history("/")}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-10 col-md-10 col-sm-9 col-xs-9 col-9">
              <div className="header-right">
                <div className="header-top">
                  <div className="mobile-hide input-group">
                    <span class="input-group-text" id="basic-addon1">
                      {" "}
                      <img src="/assets/img/search.svg" />
                    </span>
                    <input
                      class="form-control"
                      placeholder="Search here..."
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <a href="#">
                    <img src="/assets/img/mail.svg" />
                    {websitedetails?.website_email}
                  </a>
                  <a href="#">
                    <img src="/assets/img/phone.svg" />
                    {websitedetails?.company_dial_code}{" "}
                    {websitedetails?.company_mobile_no}
                  </a>
                </div>
                <div className="header-bottom headerbMob">
                  <nav className="navbar navbar-expand-lg navbar-light relative ">
                    <div className="container-fluid">
                      <div className="mobile-show ">
                        <div className="input-group">
                          <span class="input-group-text" id="basic-addon1">
                            {" "}
                            <img src="/assets/img/search.svg" />
                          </span>
                          <input
                            type="search"
                            class="form-control"
                            placeholder="Search here..."
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>

                      <button
                        className="navbar-toggler maintgl"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>

                      <div
                        className="collapse navbar-collapse maincpls"
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 ulgb">
                          {window.location.pathname === "/" || window.location.pathname === "/home" ? (
                            ""
                          ) : (
                            <li className="nav-item">
                              <Link
                                className="nav-link active"
                                aria-current="page"
                                to={`/home`}
                              >
                                Home
                              </Link>
                            </li>
                          )}

                          {/* {(user?.role_name == "Customer" ||
                            !localStorage.getItem("token")) || !LocalSlug && (
                              <li className="nav-item">
                                <Link
                                  className="nav-link active"
                                  aria-current="page"
                                  to={
                                    localStorage?.getItem("merSlug")
                                      ? `/products/merchant/${localStorage?.getItem(
                                        "merSlug"
                                      )}`
                                      : `/products`
                                  }
                                >
                                  Products
                                </Link>
                              </li>
                            )} */}

                          {user?.role_name == "Customer" || user?.role_name == "Guest" && Token ||
                            LocalSlug && user?.role_name != "Merchant" ?
                            <li className="nav-item">
                              <Link
                                className="nav-link active"
                                aria-current="page"
                                to={
                                  localStorage?.getItem("merSlug")
                                    ? `/products/merchant/${localStorage?.getItem(
                                      "merSlug"
                                    )}`
                                    : `/products`
                                }
                              >
                                Products
                              </Link>
                            </li>
                            : null}

                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Pages
                            </a>
                            <ul
                              className="dropdown-menu box-shadow-unset internal-pages-dropdown"
                              aria-labelledby="navbarDropdown"
                            >
                              {Pages.map((item) => (
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    aria-current="page"
                                    to={`/internalpages/${item.slug}`}
                                  >
                                    {item?.title}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link "
                              to={`/blogs`}
                              tabindex="-1"
                              aria-disabled="true"
                            >
                              Blog
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={`/contactus`}
                              tabindex="-1"
                              aria-disabled="true"
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                        <form className="d-flex align-items-center colit">
                          {/* {((localStorage.getItem("token") &&
                            user?.role_name != "Customer") ||
                            "Guest") && (
                              <div class="form-check form-switch mb-0 position-relative w-200 pl-0">
                                <label
                                  class="form-check-label text-white mr-5"
                                  for="flexSwitchCheckDefault"
                                >
                                  Sandbox
                                </label>
                                <input
                                  class="form-check-input form-toggle"
                                  onChange={handleModeChange}
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={mode?.mode}
                                />
                                <label
                                  class="form-check-label text-white"
                                  for="flexSwitchCheckDefault"
                                >
                                  Live
                                </label>
                              </div>
                            )} */}


                          {user.loggedIn ? (
                            <>
                              <div className="dropdown ml-lg-auto profile-dropdown">
                                <a
                                  data-toggle="dropdown"
                                  className="nav-link dropdown-toggle nav-link-user text-dark d-flex align-items-center"
                                >
                                  <img
                                    alt="image"
                                    src={methodModel.userImg(user.image)}
                                    width={40}
                                    height={40}
                                    className="rounded-circle"
                                  />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right position-absolute shadow bg_hover prodiv_cl">
                                  <div className="text-center">
                                    <img
                                      alt="image"
                                      src={methodModel.userImg(user.image)}
                                      width={50}
                                      height={50}
                                      className="rounded-circle drop_innerimg mb-2"
                                    />
                                    <div className="ml-1 ">
                                      <b className="mt-2">{user.fullName}</b>
                                      <p className="dropgray mb-0 text-capitalize mt-1">
                                        {user.role?.name}
                                      </p>
                                    </div>
                                  </div>
                                  <Link
                                    to="/dashboard"
                                    className="dropdown-item has-icon"
                                  >
                                    <i
                                      class="fa fa-th-large"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Dashboard
                                  </Link>

                                  <Link
                                    to="/profile"
                                    className="dropdown-item has-icon"
                                  >
                                    <i className="fa fa-user mr-1" /> Profile
                                  </Link>
                                  <Link
                                    to="/change"
                                    className="dropdown-item has-icon "
                                  >
                                    <i
                                      class="fa fa-lock me-2"
                                      aria-hidden="true"
                                    ></i>
                                    Change Password
                                  </Link>
                                  {user?.role_name != "Customer" &&
                                    user?.role_name != "Guest" &&
                                    user?.active_accounts <= 0 && (
                                      <a
                                        id="WantLogout"
                                        // onClick={() => open()}
                                        onClick={() => handleClick()}
                                        className="dropdown-item has-icon"
                                      >
                                        <i className="fa fa-university mr-1" />{" "}
                                        Connect a bank account
                                      </a>
                                    )}
                                  <a
                                    id="WantLogout"
                                    onClick={() => {
                                      Logout();
                                    }}
                                    className="dropdown-item has-icon"
                                  >
                                    <i className="fas fa-sign-out-alt mr-1" />{" "}
                                    Logout
                                  </a>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <a
                                onClick={(e) => history("/login")}
                                className="login-btn"
                              >
                                Login
                              </a>

                              {/* <button
                                className="btn dark-btn"
                                onClick={(e) => {
                                  history(`/signup`);
                                }}
                              >
                                Sign up
                                <img
                                  src="/assets/img/right-arrow.svg"
                                  className="ms-4"
                                />
                              </button> */}
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default WebsiteHeader;
