import React, { useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import methodModel from "../../components/validations/Validations";
import CommonDeleteModal from "../../models/delete.modal";
import CommonActiveModal from "../../models/active-deactive.modal";

const Html = ({
  view,
  edit,
  reset,
  add,
  tab,
  statusChange,
  pageChange,
  deleteItem,
  filters,
  loaging,
  data,
  decrypt,
  ShowActiveModal,
  setShowActiveModal,
  ShowDeleteModal,
  setShowDeleteModal,
  isAllow,
  total = { total },
}) => {
  const [DeleteId, setDeleteId] = useState("");
  const Delete = () => {
    deleteItem(DeleteId);
  };
  return (
    <Layout>
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="hedding">Cardpointe Credentials</h3>

        <article className="d-flex filterFlex phView">
          <a className="btn btn-primary mr-2" onClick={(e) => add()}>
            <i className="fa fa-plus me-2"></i>
            Add Cardpointe Credential
          </a>
        </article>
      </div>
        <>
          <div className="table-responsive table_section">
            <table class="table">
              <thead className="table_head">
                <tr className="heading_row">
                  <th scope="col" className="table_data">
                    User Name
                  </th>
                  <th scope="col" className="table_data">
                    Mode
                  </th>
                  <th scope="col" className="table_data">
                    Site
                  </th>
                  <th scope="col" className="table_data">
                    Currency
                  </th>
                  <th scope="col" className="table_data">
                    Api User Name
                  </th>
                  <th scope="col" className="table_data">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loaging &&
                  data &&
                  data.map((itm, i) => {
                    return (
                      <tr className="data_row">
                        <td
                          className="table_dats "
                          style={{ textTransform: "lowercase" }}
                        >
                          <span className="namefull">
                            {" "}
                            {itm.fullName || "N/A"}
                          </span>
                        </td>
                        <td
                          className="table_dats"
                          style={{ textTransform: "lowercase" }}
                        >
                          {itm.mode}
                        </td>
                        <td
                          className="table_dats"
                          style={{ textTransform: "lowercase" }}
                        >
                          {decrypt(itm.site)}
                        </td>
                        <td className="table_dats">{decrypt(itm?.currency)}</td>
                        <td className="table_dats">
                          {decrypt(itm?.authuname)}
                        </td>

                        {/* dropdown */}
                        <td className="table_dats">
                          <div className="action_icons">
                            {isAllow("editAdmins") ? (
                              <>
                                <a
                                  className="edit_icon"
                                  title="Edit"
                                  onClick={(e) => edit(itm.id)}
                                >
                                  <i class="material-icons edit" title="Edit">
                                    edit
                                  </i>
                                </a>
                              </>
                            ) : (
                              <></>
                            )}

                            {isAllow("deleteAdmins") ? (
                              <>
                                <span
                                  className="edit_icon"
                                  title="Delete"
                                  onClick={() => {
                                    setDeleteId(itm.id);
                                    setShowDeleteModal("block");
                                  }}
                                >
                                  <i
                                    class="material-icons delete"
                                    title="Delete"
                                  >
                                    {" "}
                                    delete
                                  </i>
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {!loaging && data?.length == 0 ? (
              <div className="py-3 text-center">No Data</div>
            ) : (
              <></>
            )}
          </div>
        </>
    

      {!loaging && total > filters.count ? (
        <div className="paginationWrapper">
          <span>
            Show {filters.count} from {total} Users
          </span>
          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Html;
