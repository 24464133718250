import React from "react";
import ImageUpload from "../../../components/common/imageUpload";
import Layout from "../../../components/global/layout";
import rolesModel from "../../../models/roles.model";
import SelectDropdown from "../../../components/common/selectdropdown";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import environment from "../../../environment";
import { Editor } from "@tinymce/tinymce-react";

const Html = ({
  id,
  role,
  form,
  handleSubmit,
  setform,
  category,
  history,
  submitted,
  images,
  imageResult,
  back,

}) => {
  const user = useSelector((state) => state.user);
  const isAllow = (key = "") => {
    let permissions = user.role?.permissions;
    let value = permissions?.[key];
    if (user.role.id == environment.adminRoleId) value = true;
    return value;
  };

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1">
            <div className="text-right title-head">
              <div className="d-flex justify-content-start ">
                <h3 className="hedding">
                  {" "}
                  <a onClick={(e) => back()} className="cursor-pointer">
                    <i
                      className="fa fa-arrow-left me-2 "
                      title="Back"
                      aria-hidden="true"
                    ></i>
                  </a>{" "}
                  {id ? "Edit" : "Add"}{" "}
                  {role ? rolesModel.name(role) : "Product"}
                </h3>
              </div>
            </div>
            <div className="product-detail-page">
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label>
                    Product Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={form.name}
                    onChange={(e) => setform({ ...form, name: e.target.value })}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label>
                    Category<span className="text-danger">*</span>
                  </label>
                  <SelectDropdown
                    id="statusDropdown"
                    displayValue="name"
                    className="w-100 category-field"
                    placeholder="Select Category"
                    intialValue={form.category_id}
                    // disabled={form.id ? true : false}
                    result={(e) => {
                      setform({ ...form, category_id: e.value });
                    }}
                    options={category.sort((a, b) => a.name.localeCompare(b.name))}
                    required
                  />
                  {submitted && !form.category_id ? (
                    <div className="invalid-feedback d-block">
                      Category is Required
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="col-md-6 mb-3">
                  <label>Price($)</label>
                  <input
                    type="number"
                    className="form-control"
                    value={form.price}
                    onChange={(e) => {
                      setform({ ...form, price: e.target.value });
                    }}
                    required
                  />
                </div>

                <div className="col-md-12 mb-3">
                  <label>
                    Description <span className="text-danger">*</span>
                  </label>
                  <Editor
                    required
                    apiKey="e9b46x5ebse3zswyqxc5gpl8b5zzduu2ziq9r75c2s91ytpe"
                    value={form.description}
                    onEditorChange={(newValue, editor) => {
                      setform({
                        ...form,
                        description: newValue,
                      });
                    }}
                    init={{
                      height: "300px",
                      selector: "textarea",
                      plugins: "table code colorpicker textcolor",
                      toolbar:
                        "table code bold italic alignleft aligncenter alignright alignjustify | outdent indent ",
                      toolbar_mode: "floating",
                    }}
                  />
                  {submitted && !form.description ? (
                    <div className="invalid-feedback d-block">
                      Description is Required
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="col-md-12  mb-3">
                  <label className="lablefontcls">Image <span className="text-danger">*</span></label>
                  <br></br>
                  <ImageUpload
                    model="customer"
                    result={(e) => imageResult(e, "images")}
                    value={images}
                    multiple={true}
                  />
                  {submitted && images.length == 0 ? (
                    <div className="invalid-feedback d-block">
                      Image is Required
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="text-right">
                <button
                  onClick={() => history("/product")}
                  className="btn btn-secondary mr-2"
                >
                  Back
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default Html;
