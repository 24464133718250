import React from "react";
import methodModel from "../../../components/validations/Validations";
import "./style.scss";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import addressModel from "../../../models/address.model";
import GooglePlaceAutoComplete from "../../../components/common/googleplaceautocomplete";
import Header from "../../../components/global/layout/Header";
import Footer from "../../../components/global/footer/Footer";
import { FaLongArrowAltLeft } from "react-icons/fa";

const Html = ({
  handleSubmit,
  setForm,
  form,
  uploadImage,
  submitted,
  setAddressSellected,
}) => {
  const user = useSelector((state) => state.user);
  const addressResult = async (e) => {
    let address = {};
    if (e.place) {
      address = addressModel.getAddress(e.place);
      setAddressSellected(true);
    } else {
      setAddressSellected(false);
    }
    setForm({
      ...form,
      address: e.value,
      country: address.country || "",
      city: address.city || "",
      state: address.state || "",
      zipcode: address.zipcode || "",
      lat: address.lat || "",
      lng: address.lng || "",
    });
    if (e.place) {
      setForm({
        ...form,
        address: e.value,
        country: address.country || "",
        city: address.city || "",
        state: address.state || "",
        zipcode: address.zipcode || "",
        lat: address.lat || "",
        lng: address.lng || "",
      });
    }
  };
  return (
    <>
      <Header />
      <>
        <div className="top-padding bg-white">
          <div className="container">
            <div className=" main_profile_page profile-new edit-profile">
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <div className="right_profile mt-3">
                    <div className="title-head">
                      <div className="d-flex justify-content-between align-items-center ">
                        <h3 className="hedding mb-0">
                          {" "}
                          <FaLongArrowAltLeft className="arrow-color" />
                          Edit Profile
                        </h3>
                      </div>
                    </div>
                    <div className="product-detail-page">
                      <form className="" onSubmit={handleSubmit}>
                        <div className="">
                          <div className="row">
                            <div className="col-md-4  ">
                              <div className="profile_img_side ">
                                <div className="profile-sect-m">
                                  <img
                                    src={methodModel.userImg(
                                      form && form.image
                                    )}
                                    className="profileImage"
                                  />

                                  <div>
                                    <label
                                      className="new_images edit d-flex justify-content-center
                                  "
                                    >
                                      <input
                                        id="bannerImage"
                                        type="file"
                                        className="d-none "
                                        accept="image/*"
                                        value={form.baseImg ? form.baseImg : ""}
                                        onChange={(e) => {
                                          uploadImage(e);
                                        }}
                                      />
                                      <span
                                        className=" uploaderr"
                                        title="Upload"
                                      >
                                        <i className="material-icons">
                                          camera_alt
                                        </i>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="role-display">
                                  <p>{form.role}</p>
                                </div>
                                <div className="changes_image">
                                  <div>
                                    {form.image ? (
                                      <label
                                        className="deleticon  delete "
                                        onClick={(e) =>
                                          setForm({ ...form, image: "" })
                                        }
                                      >
                                        {/* <span className="remove" title="Remove">
                              {" "}
                              <i  className="material-icons">close</i>
                            </span> */}
                                      </label>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-md-6 mb-4">
                                  <label className="label_profile">
                                    Name<span className="text-danger">*</span>
                                  </label>
                                  <div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Name"
                                      value={form.fullName ? form.fullName : ""}
                                      onChange={(e) =>
                                        setForm({
                                          ...form,
                                          fullName: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6 mb-4">
                                  <label className="label_profile">
                                    Email<span className="text-danger">*</span>
                                  </label>
                                  <div>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Name"
                                      value={form.email ? form.email : ""}
                                      disabled
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-md-6 mb-4">
                        <label className="label_profile">TimeZone<span className='text-danger'>*</span></label>
                        <div>
                        <TimezoneSelect
          value={form.timeZone}
          onChange={e=>setForm({...form,timeZone:e})}
          required
        />
                        </div>
                      </div> */}
                                <div className="col-md-6 mb-4">
                                  <label className="label_profile">
                                    Address
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <div>
                                    <GooglePlaceAutoComplete
                                      value={form.address}
                                      result={addressResult}
                                      id="address"
                                      placeholder=""
                                    />
                                    {form.address == "" && submitted ? (
                                      <div className="invalid-feedback d-block">
                                        Please Select Location Suggestion
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                {user?.role_name == "Customer" ||
                                user?.role_name == "Guest" ? null : (
                                  <div className="col-md-6 mb-4">
                                    <label className="label_profile">
                                      Business Name
                                    </label>
                                    <div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Business Name"
                                        value={
                                          form.companyName
                                            ? form.companyName
                                            : ""
                                        }
                                        onChange={(e) =>
                                          setForm({
                                            ...form,
                                            companyName: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                <div className="col-md-6 mb-3">
                                  <label className="label_profile">
                                    Mobile No{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="phoneInput_cls d-flex form-control p-0 phone_text">
                                    <PhoneInput
                                      value={form.dialCode}
                                      countryCodeEditable={false}
                                      enableSearch={true}
                                      placeholder=""
                                      onChange={(phone, country) => {
                                        setForm({
                                          ...form,
                                          dialCode: country.dialCode,
                                        });
                                      }}
                                      required
                                    />

                                    <input
                                      type="text"
                                      className="form-control phph"
                                      placeholder="Mobile No."
                                      value={(form && form.mobileNo) || ""}
                                      maxLength={12}
                                      onChange={(e) =>
                                        setForm({
                                          ...form,
                                          mobileNo: methodModel.isNumber(e),
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-md-6 mb-4">
                                  <label className="label_profile">
                                    Role<span className="text-danger">*</span>
                                  </label>
                                  <div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Name"
                                      value={form.role}
                                      disabled
                                    />
                                  </div>
                                </div> */}

                                <div className="col-md-12 text-right">
                                  {/* <Link
                                    to="/profile"
                                    className="btn btn-primary reset"
                                  >
                                    Discard
                                  </Link> */}
                                  <button
                                    type="submit"
                                    className="btn btn-primary edit ml-3"
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
};

export default Html;
