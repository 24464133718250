// Utility function for formatting numbers
const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return phoneNumber;
    return phoneNumber.trim() === '2017805880' ? '201-780-5880' : phoneNumber;
};

const formatDialCode = (dialCode) => {
    if (!dialCode) return dialCode;
    return dialCode.includes('+') ? dialCode : `+${dialCode}`;
};

// Reducer function
const website = (state = {}, action) => {
    switch (action.type) {
        case "GET_DETAILS":
            return {
                ...action.data,
                company_mobile_no: formatPhoneNumber(action.data?.company_mobile_no),
                company_dial_code: formatDialCode(action.data?.company_dial_code),
            };

        case "Error":
            return {
                ...state,
                company_mobile_no: formatPhoneNumber(state?.company_mobile_no),
                company_dial_code: formatDialCode(state?.company_dial_code),
            };

        default:
            return {
                ...state,
                company_mobile_no: formatPhoneNumber(state?.company_mobile_no),
                company_dial_code: formatDialCode(state?.company_dial_code),
            };
    }
};

export default website;
