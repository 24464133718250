import React, { useEffect } from "react";
// import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { PersistGate } from "redux-persist/es/integration/react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import configureStore from "./redux/configureStore.prod";
import "react-pagination-js/dist/styles.css";
import "./scss/main.scss";
import SignUp from "./pages/Signup";
import Forgotpassword from "./pages/auth/forgotpassword";
import Resetpassword from "./pages/Resetpassword";
import Dashboard from "./pages/dashboard";
import { Auth } from "./apimethods/auth";
import Profile from "./pages/profile";
import EditProfile from "./pages/auth/editprofile";
import ChangePassword from "./pages/auth/changePassword";

import Home from "./pages/home";
/************ store configration *********/
import { ToastContainer } from "react-toastify";
import Quiz from "./pages/quizesPages/Quiz";
import Faq from "./pages/faq";
import Blog from "./pages/blogs";
import BlogDetails from "./pages/blogs/Detail";
import CommonContentpage from "./pages/CommonContentPage/CommonContentpage";
import { WebsiteDetailAction } from "../src/redux/actions/WebsiteDetailAction";
import ContactUs from "./pages/ContactUs";
import InternalPages from "./pages/internalPages/chargeback";
import Chart from "./components/common/commonchart/Chart"; 
import Customers from "./pages/CustomerSection";
import AddEditCustomer from "./pages/CustomerSection/AddEditUser";
import CustomerDetail from "./pages/CustomerSection/Profiledetail";
import Product from "./pages/product";
import ProductDetail from "./pages/product/Productdetail";
import AddEditProduct from "./pages/product/AddEditUser";
import Products from "./pages/products";
import particularProductDetail from "./pages/products/Detail/ProductDetail";
import CardPage from "./pages/cartPage";
import CheckoutSection from "./pages/paymentprocess";
import SuccessPage from "./pages/successFullOrder";
import Orders from "./pages/orders";
import OrderDetail from "./pages/orders/Orderdetail";
import transactions from "./pages/Transactions";
import transactionDetail from "./pages/Transactions/transactiondetail"; 
import SignUpGuest from "./pages/signupasguest";
import SignUpCustomer from "./pages/signupascustomer";
import Whislist from "./pages/wishlist/Wishlist";
import MerchantCustomers from "./pages/merchantCustomer";
import CardPaymentForm from "./pages/paymentPage";
import AddCredential from "./pages/addCredential";
import AddEditCreds from "./pages/addCredential/addEditCreds";
import Funds from "./pages/funds/Funds";
import Adjustments from "./pages/funds/Adjustments";
import TokenIframe from "./pages/Tokenize/Tokenize";
import Login from "./pages/auth/login";

const { persistor, store } = configureStore();

export default () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(WebsiteDetailAction());
  }, []);

  return (
    <>
      <PersistGate loading={"loading ..."} persistor={persistor}>
        <Router>
          <Routes>
            <Route exact={true} store={store} path="/login" Component={Login} />
            <Route
              exact={true}
              store={store}
              path="/login/:slug"
              Component={Login}
            />
              <Route
              exact={true}
              store={store}
              path="/login/merchant/:slug"
              Component={Login}
            />
            <Route
              exact={true}
              store={store}
              path="/signup"
              Component={SignUp}
            />
            <Route
              exact={true}
              store={store}
              path="/signup/customer/:slug"
              Component={SignUpCustomer}
            />
            <Route
              exact={true}
              store={store}
              path="/signup/guest"
              Component={SignUpGuest}
            />
            <Route
              exact={true}
              store={store}
              path="/signup/guest/:slug"
              Component={SignUpGuest}
            />
            <Route
              exact={true}
              store={store}
              path="/forgotpassword"
              Component={Forgotpassword}
            />
            <Route
              exact={true}
              store={store}
              path="/resetpassword"
              Component={Resetpassword}
            />
            <Route
              exact={true}
              store={store}
              path="/cart"
              Component={CardPage}
            />
            <Route
              exact={true}
              store={store}
              path="/cart/:slug"
              Component={CardPage}
            />
            <Route
              exact={true}
              store={store}
              path="/placeorder"
              Component={SuccessPage}
            />
            <Route
              exact={true}
              store={store}
              path="/checkout"
              Component={CheckoutSection}
            />
            <Route
              exact={true}
              store={store}
              path="/checkout/:slug"
              Component={CheckoutSection}
            />
            <Route
              exact={true}
              store={store}
              path="/Whislist"
              Component={Whislist}
            />
            <Route
              exact={true}
              store={store}
              path="/products"
              Component={Products}
            />
            <Route
              exact={true}
              store={store}
              path="/products/merchant/:slug"
              Component={Products}
            />

            
            <Route
              exact={true}
              store={store}
              path="/products/:slug/:id"
              Component={particularProductDetail}
            />
            <Route
              exact={true}
              store={store}
              path="/products/:id"
              Component={particularProductDetail}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/orders"
              Component={Orders}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/transaction"
              Component={transactions}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/transactionDetail/:id"
              Component={transactionDetail}
            />

            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/transactionDetail/:id"
              Component={transactionDetail}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/orderDetail/:id"
              Component={OrderDetail}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/product"
              Component={Product}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/product/add"
              Component={AddEditProduct}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/product/edit/:id"
              Component={AddEditProduct}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/productDetail/:id"
              Component={ProductDetail}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/addcredential"
              Component={AddCredential}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/addeditcreds"
              Component={AddEditCreds}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/addeditcreds/edit/:id"
              Component={AddEditCreds}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/userdetail/:ccpid/:custid"
              Component={CustomerDetail}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/users/edit/:id"
              Component={AddEditCustomer}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/users/add"
              Component={AddEditCustomer}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/customers"
              Component={Customers}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/AllCustomers"
              Component={MerchantCustomers}
            />
            
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/profile"
              Component={Profile}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/profile/edit"
              Component={EditProfile}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/change"
              Component={ChangePassword}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/faq"
              Component={Faq}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/quiz"
              Component={Quiz}
            />
            <Route
              exact={true}
              path="/blogs"
              store={store}
              requireAuth={Auth}
              Component={Blog}
            />
            <Route
              exact={true}
              path="/blogsDetails/:id?"
              requireAuth={Auth}
              store={store}
              Component={BlogDetails}
            />
            <Route exact={true} path="/home" store={store} Component={Home} />
            <Route
              exact={true}
              path="/home/merchant/:slug"
              store={store}
              Component={Home}
            />

            <Route
              exact={true}
              path="/cms/:slug"
              store={store}
              Component={CommonContentpage}
            />
            <Route
              exact={true}
              path="/contactus"
              store={store}
              Component={ContactUs}
            />
            <Route
              exact={true}
              path="/dashboard"
              store={store}
              Component={Dashboard}
            />
            <Route
              exact={true}
              path="/chart/:slug"
              requireAuth={Auth}
              store={store}
              Component={Chart}
            />
            <Route
              exact={true}
              path="/payment"
              requireAuth={Auth}
              store={store}
              Component={CardPaymentForm}
            />

            <Route path="/" element={<Navigate to="/home" replace />} />

            <Route
              exact={true}
              path="/internalpages/:slug"
              store={store}
              Component={InternalPages}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/funds"
              Component={Funds}
            />

            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/adjustments"
              Component={Adjustments}
            />

            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/tokenize"
              Component={TokenIframe}
            />
          </Routes>
        </Router>
      </PersistGate>
      <div id="loader" className="loaderDiv d-none">
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};
