import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../apimethods/apiClient";
import loader from "../../../components/loader/loader";
// import "./style.scss";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import environment from "../../../environment";


const transactionDetail = (p) => {
  const history = useNavigate();
  const user = useSelector((state) => state.user);
  const { id } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    getDetail(id);
  }, [id]);
  const getDetail = (did) => {
    loader(true);
    let url = `transaction?id=${id}`;
    ApiClient.get(url).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };

  const back = () => {
    history(-1)
  };


  function decryptData(encryptedValue) {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, environment?.secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }


  return (
    <Layout>
      <div className="text-left">
        <div>
          <a className="back_arrow" to="/users" onClick={back}>
            {" "}
            <i
              className="fa fa-arrow-left mr-2 mb-3 left_arrow "
              title="Back"
              aria-hidden="true"
            ></i>{" "}
            Back{" "}
          </a>
        </div>
      </div>
      <div className="transaction_details white_bx shadow">
        <h3 className=" main_transcation mt-1 mb-3">Transaction Detail</h3>
        {/* <div className="container-fluid">
          <div className="row">
                <div className=" col-md-12">
                  

                    <div className="form-row">

                        <div className="col-md-4 mb-3">
                            <label className="profileheddingcls">Transaction Id</label>
                            <div className='profiledetailscls'>{data && data?.transactionIdentifier}</div>
                        </div>


                       

                        <div className="col-md-4 mb-3">
                            <label className="profileheddingcls">Status</label>
                            <div className='profiledetailscls'>{data && data?.status}</div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <label className="profileheddingcls">Amount($)</label>
                            <div className='profiledetailscls'>{data && data?.amount}</div>
                        </div>
                    </div>
                </div>
            </div>
          </div> */}

       <div className="table table-responsive"> <table class="table ">
          <thead>
            <tr>
              {/* <th scope="col"></th> */}
              <th scope="col">
                <label className=" table_heading ">Transaction Id</label>
              </th>
              <th scope="col">
                <label className=" table_heading ">Transaction Reference</label>
              </th>
              <th scope="col">
                <label className=" table_heading ">Merchant Name</label>
              </th>
              <th scope="col">
                <label className=" table_heading ">Customer Name</label>
              </th>
              <th scope="col">
                <label className=" table_heading ">Status</label>
              </th>
              <th scope="col">
                {" "}
                <label className=" table_heading ">Amount($)</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* <th scope="row"></th> */}
              <td>
                <div className="table_data ">
                  {data && data?.transaction_id}
                </div>
              </td>
              <td>
                <div className="table_data ">
                  {data && data?.retref || "--"}
                </div>
              </td>
              <td>
                <div className="table_data ">
                  {data && data?.merchant_name || "--"}
                </div>
              </td>

              <td>
                <div className="table_data ">
                  {data && data?.customer_name || "--"}
                </div>
              </td>
              <td>
                <div className="table_data ">{data && data?.status}</div>
              </td>
              <td>
                {" "}
                <div className="table_data ">{data && data?.response?.amount ? (decryptData(data.response?.amount) * 100).toFixed(2)
                          : "--"}</div>
              </td>
            </tr>
        
          </tbody>
        </table></div>
      </div>
    </Layout>
  );
};

export default transactionDetail;
