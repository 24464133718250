import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Filler, Tooltip } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Filler, Tooltip);

const SalesAreaChart = ({ data, period }) => {
  let labels = [];
  const totalSales = [];

  if (period === 'monthly') {
    const monthNames = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    labels = monthNames.slice(1); // Months from January to December
    totalSales.length = 12; 
    totalSales.fill(0); 

    data.forEach(item => {
      totalSales[item._id - 1] = item.totalSales; // Assuming totalSales is the key for sales data
    });
    
  } else if (period === 'yearly') {
    labels = data.map(item => item._id); // Use years as labels
    data.forEach(item => {
      totalSales.push(item.totalSales);
    });
    
  } else if (period === 'weekly') {
    labels = Array.from({ length: 53 }, (_, i) => `Week ${i + 1}`);
    totalSales.length = 53; 
    totalSales.fill(0); 

    data.forEach(item => {
      totalSales[item._id - 1] = item.totalSales;
    });
  }

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Total Sales',
        data: totalSales,
        borderColor: "#82222E",
        backgroundColor: '#82222E',
        fill: true, // Fill the area under the line
        tension: 0.4, // Smooth the line
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const index = tooltipItems[0].dataIndex;
            return labels[index]; // Return the label for the hovered data point
          },
          label: (tooltipItem) => {
            const value = tooltipItem.raw; // Get the value for the hovered data point
            return `Total Sales: $${parseFloat(value).toFixed(2)}`; // Customize the label
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: period.charAt(0).toUpperCase() + period.slice(1),
        },
      },
      y: {
        title: {
          display: true,
          text: 'Total Sales',
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div>  
      <Line data={chartData} options={options} />
    </div>
  );
};

export default SalesAreaChart;
