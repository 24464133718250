import React, { useState } from "react";
import "./style.scss";  
import Header from "../../components/global/layout/Header";

const Html = ({ 
  categoriesdata,
  CatType,
  data,
  searchHandle,
  searchChange,
  filters,
  loading
}) => { 
  const [isLiked, setIsLiked] = useState(false); 

  const handleToggleLike = () => {
    setIsLiked(!isLiked);
  };

  const iconStyle = {
    cursor: 'pointer',
    color: isLiked ? 'blue' : 'red',  
  };  
  const [SelectedVideo,setSelectedVideo]=useState(""); 
  return (
    <>
      <Header />
      <section className="faq-section mainfaq">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="faq-title text-center pb-3">
                <h2 className="faq-heading">FAQs</h2>
              </div>
            </div>
            <div className="col-md-9 ">
              <div className="text-center w-100 mb-3 d-flex justify-content-between">
          

                <form className="headerSearch " onSubmit={searchHandle}>
                  <input
                    type="search"
                    placeholder="Search..."
                    value={filters.search}
                    onChange={(e) => {
                      searchChange(e.target.value);
                    }}
                    className="Searchbar"
                  ></input>
                  <i
                    className="fa fa-search"
                    onClick={searchHandle}
                    aria-hidden="true"
                  ></i>
                  {/* {search ? (
                    <i
                      className="fa fa-times"
                      onClick={clear}
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <></>
                  )} */}
                </form>

 
                
              </div>
              {loading?  <div id="loader" className="text-center mt-4">
            <div>
                <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
            </div>
        </div>:data &&
                data.map((item, index) => {
                  return (
                    <div className="faq" id="accordion">
                      <div className="card"> 
                        <div className="faqdown">
                          <i
                             className="fa fa-caret-down"
                            aria-hidden="true"
                            data-toggle="collapse"
                            data-target={"#faqCollapse-1" + index}
                            data-aria-expanded="true"
                            data-aria-controls={"faqCollapse-1" + index}
                          ></i>
                        </div>
                        <div className="card-header" id="faqHeading-1">
                          <div className="mb-0">
                            <h5
                              className="faq-title"
                              data-toggle="collapse"
                              data-target={"#faqCollapse-1" + index}
                              data-aria-expanded="true"
                              data-aria-controls={"faqCollapse-1" + index}
                            >
                              {item.question}
                            </h5>
                          </div>
                        </div>
                        <div
                          id={"faqCollapse-1" + index}
                          className="collapse"
                          aria-labelledby="faqHeading-1"
                          data-parent="#accordion"
                        >
                          <div className="card-body card_faq">
                          <div className="d-flex justify-content-end mt-2 mb-2">
                          <div onClick={handleToggleLike} style={iconStyle}>
                          {/* <i class={`fa fa-thumbs-up thumbs like-button ml-3 mr-4   text-${item.isLiked==true ? 'info' : ''}`} aria-hidden="true" onClick={(e) => handleLike(item.id)} disabled={liked}><span className="count_cls"> {item.likes}</span></i>  */}
                          {/* <i class={`fa fa-thumbs-down thumbs like-button ml-3 mr-4 mt-1 text-${item.isUnliked==true?'info':''}`} aria-hidden="true" onClick={(e) => handleDislike(item.id)} disabled={disliked}><span className="count_cls"> {item.unlikes}</span></i> */}
                          </div> 
                          </div>
                            <p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.answer,
                                }}
                              ></div>
                              {/* {item&&item.video&&item.video!=""?<a className="text-info" onClick={e=>HandleSelectVideo(e,item.video)} data-toggle="modal" data-target="#exampleModalCenter"> watch video...</a>:null} */}
                            </p>
                          </div>
                        </div>
                      </div>
 
                    </div>
                  );
                })}
                {!loading&&data.length==0?<div className="text-center text-danger">No Data</div>:null}
            </div> 


            <div className="col-md-3 position-sticky">
           
              <ul className="category_name  p-3 mt-5" >
                <h6 className=" mt-1 mb-3 font-weight-bold">FAQs Category</h6>
                {categoriesdata && categoriesdata.map((item, index) => (
                <li className={`text-capitalize ${filters.category==item.id?"text-info":""}`}  onClick={(e) => CatType(item.id)} >{item.name} </li>
                 ))}
              </ul>
            </div>
          </div>
        </div>  

 
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        {/* <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> */}
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <video src={SelectedVideo} width={455} height={300}  controls />
      </div> 
    </div>
  </div>
</div>
      </section>
    </>
  );
};

export default Html;
