import React, { useEffect } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import Sidebar from "../sidebar";
import Header from "../header";
import {useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

const Layout = ({ children }) => {
  const user = useSelector((state) => state.user); 
  const history = useNavigate();

  useEffect(() => {
    if (user && !user.loggedIn) { 
    } 
  }, []);

  const router = () => {
    let route = localStorage.getItem("route");
    history(route);
  };
 
  

  return (
    <>
      <div component="layout">
        <div onClick={(e) => router()} id="routerDiv"></div>
        <Header />
        <div className="container-fluid custom-padding">
         <div className="main-wrapper d-flex mt-5 justify-content-between">
         <div className="main-sidebar  d-md-block">
            
            <Sidebar />
          </div>
          <main className="main">
            <div className="mainarea">{children}</div>
          </main>
         </div>
        </div>
      </div>
    </>
  );
};
export default Layout;
