import { useEffect, useRef, useState } from "react";
import Html from "./html";
import { useLocation, useNavigate } from "react-router-dom";
import ApiClient from "../../apimethods/apiClient";
import loader from "../../components/loader/loader";
import { useDispatch, useSelector } from "react-redux";

const CardPage = () => {
  const Ref = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const user = useSelector((state) => state.user);
  const [Products, setProducts] = useState([]);
  const [TotalRentProducts, setTotalRentProducts] = useState("");
  const [subtotal, setsubtotal] = useState("");
  const [quantityErr, setquantityErr] = useState(false);
  const [couponscode, setcouponscode] = useState([]);
  const [form, setform] = useState({
    fullName: "",
    dialCode: "",
    mobileNo: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    lat: "",
    lng: "",
    street: "",
  });
  const [address, setAddress] = useState(form?.address);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [productQuantities, setProductQuantities] = useState({});
  const [savedProduct, setsavedProduct] = useState([]);
  const [couponfield, setshowcouponfield] = useState({
    field: false,
    err: false,
  });
  const [CouponCode, setCouponCode] = useState("");
  const [couponData, setcouponData] = useState([]);
  const [quantity, setQuantity] = useState(5);

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    getProducts("full");
  }, []);

  const getProducts = (type = "") => {
    loader(true);
    ApiClient.get(
      `cart/summary?merchant_id=${
        localStorage?.getItem("merchant_id") || location?.state
      }`
    ).then((res) => {
      if (res?.success) {
        setProducts(res?.data?.items ? res?.data?.items : []);
        setTotalRentProducts(res?.data?.items?.length);
        localStorage.setItem("count", res?.data?.total_items);
        loader(false);
      } else {
        loader(false);
      }
    });
  };

  //remove from cart
  const removeAll = (id) => {
    let arr = [];
    arr.push(id);
    let payload = {
      product_id: id,
    };
    ApiClient.put(`cart/remove-item`, payload).then((res) => {
      if (res?.success) {
        getProducts();
      }
    });
  };

  const delateAllData = () => {
    let arr = Products;
    let arr1 = arr?.map((itm) => itm?.id);
    let payload = {
      id: arr1,
    };
    ApiClient.delete(`cart`, payload).then((res) => {
      if (res?.success) {
        dispatch(cart_get(""));
        getProducts();
      }
    });
  };

  const changeQuantity = (quantityy, item) => {
    let payload = {
      product_id: item?._id,
      qty: quantityy,
      merchant_id: localStorage?.getItem("merchant_id"),
    };
    loader(true);
    ApiClient.post(`cart/add-item`, payload).then((res) => {
      if (res?.success) {
        getProducts();
      }
      loader(false);
    });
  };

  const addItem = (index, item) => {
    if (item?.quantity < item?.product_quantity) {
      let quantity = item?.quantity + 1;
      let payload = {
        quantity: quantity,
      };
      loader(true);
      ApiClient.put(`cart?id=${item?.id}`, payload).then((res) => {
        if (res?.success) {
          getProducts();
        }
        loader(false);
      });
    } else {
      setquantityErr(true);
    }
  };

  useEffect(() => {
    if (Products) {
      let array = Products;
      let total = array?.map((itm) => {
        return itm?.qty * itm?.price;
      });
      total = total.reduce((acc, curr) => acc + curr, 0);
      setsubtotal(total);
    }
  }, [Products]);

  const checkout = () => {
    history("/checkout");
  };

  const handleChange = (newAddress) => {
    setAddress(newAddress);
    setform({ ...form, address: newAddress });
  };
  const clearaddress = () => {
    setform({ ...form, address: "", pincode: "", city: "" });
    setAddress("");
  };

  const gotoProductpage = (ide) => {
    history("/products/relate/" + ide);
  };

  return (
    <>
      <Html
        goBack={goBack}
        Products={Products}
        changeQuantity={changeQuantity}
        addItem={addItem}
        subtotal={subtotal}
        removeAll={removeAll}
        delateAllData={delateAllData}
        checkout={checkout}
        quantityErr={quantityErr}
        form={form}
        setform={setform}
        TotalRentProducts={TotalRentProducts}
        handleChange={handleChange}
        selectedLocation={selectedLocation}
        fulladdress={form?.address}
        address={address}
        clearaddress={clearaddress}
        gotoProductpage={gotoProductpage}
        savedProduct={savedProduct}
        couponfield={couponfield}
        CouponCode={CouponCode}
        setCouponCode={setCouponCode}
        couponData={couponData}
        ref={Ref}
        key="Helo"
        couponscode={couponscode}
        quantity={quantity}
        setQuantity={setQuantity}
        productQuantities={productQuantities}
        setProductQuantities={setProductQuantities}
      />
    </>
  );
};

export default CardPage;
