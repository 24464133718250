import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiClient from "../../../apimethods/apiClient";
import loader from "../../../components/loader/loader";
import "./style.scss";
import { useSelector } from "react-redux";
import methodModel from "../../../components/validations/Validations";
import environment from "../../../environment";
import WebsiteHeader from "../../../components/global/websiteheader/WebsiteHeader";

const BlogDetails = (p) => {
  const history = useNavigate();
  const user = useSelector((state) => state.user);
  const { id, userId } = useParams();
  const [data, setData] = useState();
  const getDetail = (did) => {
    loader(true);
    ApiClient.get(`blog`, { id: did }).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    getDetail(userId ? userId : id);
  }, [id, userId]);

  const [SelectedVideo, setSelectedVideo] = useState("");
  const HandleSelectVideo = (e, data) => {
    e.preventDefault();
    setSelectedVideo(`${environment.api}images/videos/${data}`);
  };
  return (
    <>
      <WebsiteHeader />
      <main className="container-fluid p-0 mb-5">
        <div className="col-md-12">
          <a className="back_overlap" to="/blog" onClick={back}>
            {" "}
            <i
              className="text-white fa fa-arrow-left mr-4 mb-3 "
              title="Back"
              aria-hidden="true"
            ></i>
          </a>

          <section className="content">
            <div className="row">
              <div className="col-md-12 p-0 position-relative">
                <div className="overlap_text">
                  <div className="text-center text-white">
                    <h2 className="heading">Blog Detail</h2>
                    <p className="subheading">{data && data.title}</p>
                  </div>
                </div>
                <img
                  src={methodModel.noImage(data && data?.image)}
                  className="uploadimage w-100"
                />
              </div>
              <br />
              <div className="col-md-8 mx-auto">
                <p>
                  {" "}
                  <div
                    className="profiledetailscls mt-3"
                    dangerouslySetInnerHTML={{
                      __html: data && data.description,
                    }}
                  ></div>
                  {data && data.video && data.video != "" ? (
                    <a
                      className="text-info"
                      onClick={(e) => HandleSelectVideo(e, data.video)}
                      data-toggle="modal"
                      data-target="#exampleModalCenter3"
                    >
                      {" "}
                      watch video...
                    </a>
                  ) : null}
                </p>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default BlogDetails;
