import React, { useEffect, useRef, useState } from "react";

const TokenIframe = ({ setToken, setError }) => {
  const iframeRef = useRef(null);

  // useEffect(() => {
  //   const handleMessage = (event) => {
  //     // window.parent.postMessage('Hello Parent', '*'); // Not recommended for production
  //     // Verify the origin of the message
  //     if (event.origin == "https://fts-uat.cardconnect.com") {
  //       console.log(event,'evntttttt');

  //       if (event?.data?.message) {
  //         let data = JSON.parse(event?.data)?.message;
  //         setToken(data);
  //         console.log("Received token:", data);
  //       } else if (event?.data?.validationError) {
  //         let error = JSON.parse(event?.data)?.validationError;
  //         setError(error);
  //         console?.log(error, "erororororo");
  //       }

  //       // Handle the token as needed
  //     }
  //   };

  //   // Add event listener for messages
  //   window.addEventListener("message", handleMessage);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     window.removeEventListener("message", handleMessage);
  //   };
  // }, []);

  // useEffect(() => {
  //   const iframe = iframeRef.current;

  //   const injectCSS = () => {
  //     if (iframe && iframe.contentDocument) {
  //       const style = iframe.contentDocument.createElement('style');
  //       style.textContent = `
  //         input[type="text"] {
  //           border: 2px solid #4CAF50;
  //           padding: 10px;
  //           border-radius: 5px;
  //           font-size: 16px;
  //         }
  //       `;
  //       iframe.contentDocument.head.appendChild(style);
  //     }
  //   };

  //   iframe.addEventListener('load', injectCSS);

  //   return () => {
  //     iframe.removeEventListener('load', injectCSS);
  //   };
  // }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      // Verify the origin of the message
      if (event.origin === "https://isv-uat.cardconnect.com") {
        try {
          const data = JSON.parse(event.data);
          if (data.message) {
            setToken(data.message);
            setError("");
          } else if (data.validationError) {
            setError("Invalid card number");
            console.error("Validation error:", data.validationError);
          } else {
            console.warn("Unknown message received:", data);
          }
        } catch (error) {
          console.error("Failed to parse message:", error);
        }
      } else {
        console.warn("Message received from unknown origin:", event.origin);
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [setToken, setError]);
  return (
    <div>
      <form>
        <iframe
        id="tokenIframe"
        src="https://isv-uat.cardconnect.com/itoke/ajax-tokenizer.html?css=input%7Bborder%3A0px%20solid%20%23efefef%3Bbackground%3A%23fff%3Bheight%3A18px%3Bpadding%3A0px%3Bposition%3Aabsolute%3Btop%3A0%3B%7Dinput%3Afocus%7Boutline%3A0%3B%7D&cardinputmaxlength=16&cardnumbernumericonly=true&invalidinputevent=true&enhancedresponse."
        title="Token Iframe"
        width="200"
        height="30"
        frameborder="0"
        autoFocus="false"
         scrolling="no"
        style={{
            border: "1px solid #dcdcdc",
            backgroundColor: "#fff",
            overflow: "hidden",
            height: " calc(1.5em + 0.75rem + 2px)",
            padding: "0px",
            borderRadius: "5px",
            width: "100%",
          }}
      />
        {/* <iframe
          id="tokenframe"
          name="tokenframe"
          src="https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?css=input%7Bborder%3A0px%20solid%20%23efefef%3Bbackground%3A%23fff%3Bheight%3A18px%3Bpadding%3A0px%3Bposition%3Aabsolute%3Btop%3A0%3B%7Dinput%3Afocus%7Boutline%3A0%3B%7D"
          scrolling="no"
          width="200"
          height="30"
          frameborder="0"
          style={{
            border: "1px solid #dcdcdc",
            backgroundColor: "#fff",
            overflow: "hidden",
            height: " calc(1.5em + 0.75rem + 2px)",
            padding: "0px",
            borderRadius: "5px",
            width: "100%",
          }}
        ></iframe> */}


    

      </form>
    </div>
  );
};

export default TokenIframe;
