import React, { useState } from "react";
import "./style.scss";
import WebsiteHeader from "../../components/global/websiteheader/WebsiteHeader";

const Html = ({
  filters,
  searchHandle,
  searchChange,
  search,
  total = { total },
}) => {
  const [SelectedVideo, setSelectedVideo] = useState("");
  return (
    <>
      <div className="blog-page">
        <div className="other-header">
          <WebsiteHeader />
        </div>

        <div class="banner-section">
          <div class="container">
            <div class="row">
              <div class="col-lg-9">
                <div className="border_blog">
                  <div class="blog-title">
                    <h2>
                      Cras nisl dolor, accumsan et metus sit amet, vulputate
                      condimentum dolor. e
                    </h2>
                  </div>
                  <div class="blog-detail-content">
                    <img src="assets/img/login-bg.png" alt="" />
                    <div class="d-flex blog-content mt-3">
                      <div class="d-flex align-items-center">
                        <img src="assets/images/calendar.svg" alt="" />
                        <p class="mb-0 ms-1">09.08.2023</p>
                      </div>
                      <div class="d-flex align-items-center ms-2">
                        <img src="assets/images/chat.svg" alt="" />
                        <p class="mb-0 ms-1">123</p>
                      </div>
                    </div>
                    <div className="blogs_para_detail">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been theLorem
                        Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the Lorem
                        Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                      </p>

                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been theLorem
                        Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the Lorem
                        Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                      </p>

                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been theLorem
                        Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the Lorem
                        Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div className="border_blog">
                  <form
                    className="headerSearch"
                    onSubmit={(e) => searchHandle(e)}
                  >
                    <input
                      type="search"
                      placeholder="Search..."
                      value={filters.search}
                      onChange={(e) => {
                        searchChange(e);
                      }}
                      className="Searchbar"
                    ></input>
                    <i
                      className="fa fa-search"
                      onClick={searchHandle}
                      aria-hidden="true"
                    ></i>
                    {search ? (
                      <i
                        className="fa fa-times"
                        onClick={clear}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <></>
                    )}
                  </form>
                  <div class="other-blog-top">
                    <h2>Other Blogs</h2>
                    <div class="other-blogs">
                      <div class="blog-detail-sect mb-4">
                        <img src="assets/img/login-bg.png" alt="" />
                        <div class="d-flex blog-content mt-3">
                          <div class="d-flex align-items-center">
                            <img src="assets/images/calendar.svg" alt="" />
                            <p class="mb-0 ms-1">09.08.2023</p>
                          </div>
                          <div class="d-flex align-items-center ms-2">
                            <img src="assets/images/chat.svg" alt="" />
                            <p class="mb-0 ms-1">123</p>
                          </div>
                        </div>
                        <h6>Cras nisl dolor, accumsan et metus sit amet.</h6>
                        <a href="#">Read more... </a>
                      </div>
                      <div class="blog-detail-sect">
                        <img src="assets/img/login-bg.png" alt="" />
                        <div class="d-flex blog-content mt-3">
                          <div class="d-flex align-items-center">
                            <img src="assets/images/calendar.svg" alt="" />
                            <p class="mb-0 ms-1">09.08.2023</p>
                          </div>
                          <div class="d-flex align-items-center ms-2">
                            <img src="assets/images/chat.svg" alt="" />
                            <p class="mb-0 ms-1">123</p>
                          </div>
                        </div>
                        <h6>Cras nisl dolor, accumsan et metus sit amet.</h6>
                        <a href="#">Read more...</a>
                      </div>
                    </div>
                  </div>
                  <div class="tags">
                    <h2>Related Tags</h2>
                    <ul>
                      <li>
                        <a href="#">Blogs</a>
                      </li>
                      <li>
                        <a href="#">Blogs</a>
                      </li>
                      <li>
                        <a href="#">Blogs</a>
                      </li>
                      <li>
                        <a href="#">Blogs</a>
                      </li>
                      <li>
                        <a href="#">Blogs</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModalCenter3"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header p-0">
              {/* <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> */}
              <button
                type="button"
                class="close close_icon"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <video src={SelectedVideo} width={455} height={300} controls />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Html;
