import { forwardRef, useEffect, useState } from "react";
import "./style.scss";
import methodModel from "../../components/validations/Validations";
import "react-phone-input-2/lib/style.css";
import Header from "../../components/global/layout/Header";
import Footer from "../../components/global/footer/Footer";
import { TiMinus } from "react-icons/ti";
import { GoPlus } from "react-icons/go";
import { TiDeleteOutline } from "react-icons/ti";

const Html = forwardRef(
  ({ Products, removeAll, checkout, subtotal, changeQuantity }) => {
    const [productId, setproductId] = useState([]);
    const [cartproduct, setcartproduct] = useState([]);
    const [total, settotal] = useState("");

    useEffect(() => {
      if (cartproduct) {
        let total = cartproduct
          .map((itm) => itm?.qty * itm?.price)
          .reduce((acc, curr) => acc + curr, 0);
        settotal(total);
      }
    }, [cartproduct]);

    useEffect(() => {
      let arr = productId;
      let arr2 = [];
      if (arr.length >= 1) {
        arr.forEach((item) => {
          Products.forEach((itm) => {
            if (itm?._id === item) {
              arr2.push(itm);
            }
          });
        });
      }
      setcartproduct([...arr2]);
    }, [Products]);

    const selectall = () => {
      if (Products?.length > 0) {
        let arr = Products.map((item) => item?._id);
        setproductId([...arr]);
        setcartproduct([...Products]);
      }
    };

    useEffect(() => {
      selectall();
    }, [Products]);

    const handleQuantityChange = (newQuantity, item) => {
      changeQuantity(newQuantity, item);
      setcartproduct((prev) =>
        prev.map((prod) =>
          prod._id === item._id ? { ...prod, qty: newQuantity } : prod
        )
      );
    };

    const QuantityInput = ({ item }) => {
      const [quantity, setQuantity] = useState(item.qty || 1);

      const increment = () =>
        setQuantity((prev) => {
          const newQty = prev + 1;
          handleQuantityChange(newQty, item);
          return newQty;
        });

      const decrement = () => {
        if (quantity > 1) {
          const newQty = quantity - 1;
          handleQuantityChange(newQty, item);
          return newQty;
        }
        return quantity;
      };

      const handleInputChange = (e) => {
        const newQuantity = Math.max(1, parseInt(e.target.value, 10) || 1);
        setQuantity(newQuantity);
        handleQuantityChange(newQuantity, item);
      };

      return (
        <div className="qty_btn d-flex align-items-center">
          <p
            className="m-0 mr-2 minues-plus cursor-pointer "
            onClick={() => {
              decrement();
            }}
            disabled={quantity <= 1}
          >
            {" "}
            <TiMinus className="set-cursor " />
          </p>
          <input
            type="number"
            value={quantity}
            onChange={handleInputChange}
            min="1"
            className="qty_input"
          />
          <p
            className="m-0 ml-2 minues-plus "
            onClick={() => {
              increment();
            }}
          >
            {" "}
            <GoPlus className="set-cursor " />
          </p>
        </div>
      );
    };

    return (
      <>
        <Header />
        <div className="top-padding">
          <div className="checkout_wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-9 col-lg-8 col-xl-8">
                  <div className="checkout_page">
                    <div className="heading_check ">
                      <div className="d-flex justify-content-between align-items-center">
                        {Products?.length > 0 ? (
                          <h3>My Cart</h3>
                        ) : (
                          <h3>Your Cart is Empty</h3>
                        )}
                      </div>
                    </div>
                    {Products?.length > 0 ? (
                      <div className="heading-list mb-2">
                        <div className="row mx-0  align-items-center">
                          <div className="col-md-2 header-text">Product</div>
                          <div className="col-md-3 header-text">Name</div>
                          <div className="col-md-3 header-text">Quantity</div>
                          <div className="col-md-2 header-text">Price</div>
                          <div className="col-md-2"></div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {Products.length > 0 ? (
                      Products.map((item) => (
                        <div className="card_listing  pt-3 pb-3" key={item._id}>
                          <div className="row mx-0 align-items-center">
                            <div className="col-md-2">
                              <div className="d-flex align-items-center overflow-hidden space_gap">
                                <img
                                  src={
                                    item?.images?.length > 0
                                      ? methodModel.noImg(item?.images[0])
                                      : ""
                                  }
                                  className="laptop_cart_new img-fluid"
                                  alt="laptop"
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <h6 className="detail_cl text-capitalize">
                                {item?.name}
                              </h6>
                            </div>
                            <div className="col-md-3">
                              <QuantityInput item={item} />
                            </div>
                            <div className="col-md-2">
                              <p className="Stock_cl text-capitalize mb-0 ">
                                <span className="seller_cl">
                                  ${item?.price}
                                </span>
                              </p>
                            </div>

                            <div className=" col-md-2">
                              <span
                                onClick={() => removeAll(item?._id)}
                                style={{ cursor: "pointer" }}
                              >
                                <TiDeleteOutline className="set-remove" />
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-12 col-lg-12 px-lg-12 mb-md-0 mb-lg-12 jus">
                          <div className="pointer" title="">
                            <div className="p-3">
                              <div className="text-center ">
                                <img
                                  src={
                                    "https://static.vecteezy.com/system/resources/thumbnails/005/006/007/small/no-item-in-the-shopping-cart-click-to-go-shopping-now-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-vector.jpg"
                                  }
                                  className="flash_sales_img img-fluid slider_img_height"
                                  alt="img"
                                />
                              </div>
                              <div className="text-center mt-3">
                                <p className="real_price text-capitalize">
                                  Your Cart is Empty
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {cartproduct.length > 0 ? (
                  <div className="col-12 col-md-3 col-lg-4 col-xl-4">
                    <div className="sideleft_checkout">
                      <div className="select_product">
                        <div className="box-shadow ">
                          <div>
                            <h3 className="cart_sel">Order Summary</h3>
                          </div>
                          <p className="cart_numbs mb-2">
                            <span className="ml-2">$</span>
                            {subtotal}
                          </p>

                          {cartproduct.length === 0 ? null : (
                            <div className="row px-2 mb-2">
                              <div className="col-6">
                                <p className="cart_pros">Products</p>
                              </div>
                              <div className="col-6 text-right">
                                <p className="cart_pros">
                                  {cartproduct.length}
                                </p>
                              </div>
                            </div>
                          )}

                          {cartproduct.length > 0 && (
                            <div className="cart_pro_footer">
                              {cartproduct.map((itm) => (
                                <div
                                  className="profit_list border-bottom"
                                  key={itm._id}
                                >
                                  <p className="product_hedng text-capitalize ">
                                    {itm?.name}
                                  </p>
                                  <div className="">
                                    <div className="product_aweosme size_cl fexling_class text-capitalize m-0">
                                      <p className="gb_cl">
                                        {"Product Price"}:
                                      </p>
                                      <p>
                                        $ {itm?.qty} *{" "}
                                        {parseFloat(itm?.price).toFixed(2)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <div className="profit_list bordertop1">
                                <div className="d-flex justify-content-between">
                                  <p className="cart_pros_footer total_amt">
                                    Total amount
                                  </p>
                                  <p className="cart_pros_totl total_amt">
                                    ${parseFloat(total).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="btn_checkout mt-4">
                            <button
                              type="button"
                              className={"btn btn-primary w-100"}
                              disabled={cartproduct.length === 0}
                              onClick={() => {
                                checkout();
                                localStorage.setItem(
                                  "cart_products",
                                  JSON.stringify(cartproduct)
                                );
                              }}
                            >
                              Proceed To Checkout
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
);

export default Html;
