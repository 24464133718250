import React from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import datepipeModel from "../../models/datepipemodel";
import RefundModal from "../../models/refund.modal";
import SelectDropdown from "../../components/common/selectdropdown";

const Html = ({
  pageChange,
  filters,
  loaging,
  data,
  history,
  changestatus,
  ShowRefundModel,
  setShowRefundModal,
  refund,
  user,
  decryptData,
  total = { total },
  statuses,
  ChangeRole,
  clear,
  handleSendRefundRequest,
  customerStatus,
}) => {
  return (
    <Layout>
      <RefundModal
        show={ShowRefundModel}
        setShow={setShowRefundModal}
        confirm={refund}
      />
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="hedding">Transactions</h3>

        <div className="d-flex gap-2 ml-auto">
          <div className="d-flex gap-1 mr-4 align-items-center">
            <label className="mb-0">
              Show Refunds<span className="text-danger">*</span>
            </label>
            <div className="form-switch m-0 wswitch">
              <input
                type="checkbox"
                className="form-check-input bigToggle"
                id="isAchToggle"
                checked={filters.isRefund}
                onChange={(e) => ChangeRole(e?.target?.checked)}
              />
            </div>
          </div>
          <SelectDropdown
            id="statusDropdown"
            displayValue="name"
            placeholder="All Status"
            intialValue={filters.status}
            result={(e) => {
              changestatus(e.value);
            }}
            options={user?.role?.name == "Customer" ? customerStatus : statuses}
          />
          {filters.status ? (
            <>
              <button className="btn btn-secondary" onClick={() => clear()}>
                Reset
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <>
        <div className="table-responsive  mt-4 new-table">
          <table className="table border">
            <thead className="table_head">
              <tr className="heading_row">
                {user.role_name == "Customer" ? (
                  <th scope="col" className="table_data">
                    Merchant Name
                  </th>
                ) : (
                  <th scope="col" className="table_data">
                    Customer Name
                  </th>
                )}
                <th scope="col" className="table_data">
                  Amount
                </th>
                <th scope="col" className="table_data">
                  Order Date
                </th>

                <th scope="col" className="table_data">
                  Transaction Reference
                </th>
                <th scope="col" className="table_data">
                  Transaction Id
                </th>
                <th scope="col" className="table_data" ordertra>
                  Payment Status
                </th>

                {user.role_name != "Customer" ? (
                  <th scope="col" className="table_data">
                    {" "}
                    Refund
                  </th>
                ) : (
                  ""
                )}
                {user.role_name == "Customer" ? (
                  <th scope="col" className="table_data">
                    {" "}
                    Refund
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {!loaging &&
                data &&
                data.map((itm, i) => {
                  return (
                    <tr className="data_row">
                      <td className="table_dats">
                        {user.role_name == "Customer"
                          ? itm?.merchant_name
                          : itm?.customer_name}
                      </td>
                      <td className="table_dats">
                        $
                        {itm?.amount
                          ? (decryptData(itm?.response?.amount) * 100).toFixed(
                              2
                            )
                          : "0"}
                      </td>
                      <td className="table_dats ">
                        <span className="namefull">
                          {" "}
                          {datepipeModel.date(itm.createdAt)}
                        </span>
                      </td>
                      <td>
                        <div className="table_data ">
                          {(itm && itm?.retref) || "--"}
                        </div>
                      </td>
                      <td className="table_dats">
                        <span className="tableElipsis">
                          {itm?.transaction_id}
                        </span>
                      </td>
                      {user.role_name === "Customer" ? (
                        <td className="table_dats">
                          <div className={`user_hours ${itm.status}`}>
                            <span className="contract">
                              {itm.status == "failed" ? "Failed" : "Success"}
                            </span>
                          </div>
                        </td>
                      ) : (
                        <td className="table_dats">
                          <div className={`user_hours ${itm.status}`}>
                            <span className="contract">
                              {itm.status}
                            </span>
                          </div>
                        </td>
                      )}
                      {user.role_name === "Customer" ? (
                        <td>
                          <button
                            disabled={itm?.refundStatus == "pending"}
                            onClick={() => {
                              itm?.refundStatus != "pending"
                                ? handleSendRefundRequest(itm?.id || itm?._id)
                                : "";
                            }}
                            className={`btn   removeBg ${
                              itm?.refundStatus == "pending"
                                ? "reqbtn"
                                : "sendbtn"
                            }`}
                          >
                            {itm?.refundStatus == "pending"
                              ? "Requested"
                              : "Send Request"}
                          </button>
                        </td>
                      ) : (
                        <td className="table_dats">
                          <button
                            disabled
                            className={`btn   removeBg ${
                              itm?.refundStatus == "pending"
                                ? "reqbtn"
                                : "sendbtn"
                            }`}
                          >
                            {itm?.refundStatus == "pending" ? "Requested" : ""}
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {!loaging && total == 0 ? (
            <div className="py-3 text-center no-data">
              <img src="assets/img/no-products-found.png" />
              No Data
            </div>
          ) : (
            <></>
          )}
        </div>
      </>

      {!loaging && total > filters.count ? (
        <div className="paginationWrapper">
          <span>
            Show {filters.count} from {total} transactions
          </span>
          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Html;
