import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const BarGraph = ({ data, period }) => {
  let labels = [];
  const totalRefunds = [];

  if (period === "monthly") {
    const monthNames = [
      "",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    labels = monthNames.slice(1);
    totalRefunds.length = 12;
    totalRefunds.fill(0);

    data.forEach((item) => {
      totalRefunds[item._id - 1] = item.refundAmount;
    });
  } else if (period === "yearly") {
    labels = data.map((item) => item._id);
    data.forEach((item) => {
      totalRefunds.push(item.refundAmount);
    });
  } else if (period === "weekly") {
    labels = Array.from({ length: 53 }, (_, i) => `Week ${i + 1}`);
    totalRefunds.length = 53;
    totalRefunds.fill(0);

    data.forEach((item) => {
      totalRefunds[item._id - 1] = item.refundAmount;
    });
  }

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Total Refunds",
        data: totalRefunds,
        backgroundColor: "#82222E",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Refunds",
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const index = tooltipItems[0].dataIndex;
            return labels[index];
          },
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            return `Total Refunds: ${value}`;
          },
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default BarGraph;
