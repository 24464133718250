import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiClient from "../../apimethods/apiClient";
import "./style.scss";
import loader from "../../components/loader/loader";
import userTableModel from "../../models/table.model";
import Html from "./html";
import { userType } from "../../models/type.model";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import environment from "../../environment";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";

const transactions = (p) => {
  const userId = localStorage?.getItem("userid");
  const user = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);
  const [filters, setFilter] = useState({
    page: 1,
    count: 10,
    search: "",
    isDeleted: false,
    isRefund : false,
  });
  const [data, setData] = useState([]);
  const [tab, setTab] = useState("list");
  const [total, setTotal] = useState(0);
  const [loaging, setLoader] = useState(true);
  const [tableCols, setTableCols] = useState([]);
  const [form, setform] = useState(userType);
  const [roles, setRoles] = useState([]);
  const [refundId, setRefundid] = useState("");
  const [ShowRefundModal, setShowRefundModal] = useState("none");
  const [retref, setRetRef] = useState("");
  const location = useLocation()
  const history = useNavigate();

 

 
 
  
  useEffect(() => {
    if (user && user.loggedIn) {
      const isRefundValue = location?.state?.isRefund === true; 
      setFilter((prevFilters) => ({
        ...prevFilters,
        isRefund: isRefundValue,
      }));
      getData({ search: searchState.data, page: 1, isRefund: isRefundValue });
    }
  }, [searchState, user, location.state]);  
  
  

  const uTableCols = () => {
    let exp = [];
    if (tableCols) exp = tableCols;
    let value = [];
    userTableModel.list.map((itm) => {
      if (itm != exp.find((it) => it.key == itm.key)) {
        value.push(itm);
      }
    });
    return value;
  };

  const addCol = (itm) => {
    setTableCols([...tableCols, itm]);
  };

  const removeCol = (index) => {
    let exp = tableCols;
    exp.splice(index, 1);
    setTableCols([...exp]);
  };

  const getData = (p = {}) => {
    setLoader(true);
    let filter = { ...filters, ...p };
    if (user.role_name === "Customer") {
      filter.customer_id = userId;
    } else {
      filter.merchant_id = userId;
    }
    let url = "transaction/all";
    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        setData(res.data);
        setTotal(res.total);
      }
      setLoader(false);
    });
  };

  const clear = () => {
    setFilter({ ...filters, search: "", page: 1, status: "" });
    getData({ search: "", page: 1, status: "" });
  };

  const deleteItem = (id) => {
    if (window.confirm("Do you want to delete this")) {
      loader(true);
      ApiClient.delete("api/user/delete", { id: id }).then((res) => {
        if (res.success) {
          toast?.success(res.message);
          clear();
        }
        loader(false);
      });
    }
  };

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  };

  const openModal = (itm) => {
    let extra = new Date().getTime();
    setform({ ...itm, extra });
    document.getElementById("openuserModal").click();
  };

  const ChangeRole = (e) => {
    setFilter({ ...filters, isRefund: e, page: 1 });
    getData({ isRefund: e, page: 1 });
  };
  
  const changestatus = (e) => {
    setFilter({ ...filters, status: e, page: 1 });
    getData({ status: e, page: 1 });
  };
  const exportCsv = () => {
    loader(true);
    ApiClient.get("user/csv").then((res) => {
      if (res.success) {
        let url = res.path;
        let downloadAnchor = document.getElementById("downloadJS");
        downloadAnchor.href = url;
        downloadAnchor.click();
      }
      loader(false);
    });
  };

  const colClick = (col, itm) => {
    if (col.key == "healthClinicId") {
    }
  };

  const statusChange = (itm) => {
    let modal = "users";
    let status = "active";
    if (itm.status == "active") status = "deactive";

    if (
      window.confirm(
        `Do you want to ${status == "active" ? "Activate" : "Deactivate"
        } this user`
      )
    ) {
      loader(true);
      ApiClient.put(`api/user/status/change`, { status, id: itm.id }).then(
        (res) => {
          if (res.success) {
            getData();
          }
          loader(false);
        }
      );
    }
  };

  const blockunblock = (itm) => {
    if (
      window.confirm(
        `Do you want to ${!itm.isBlock ? "Block" : "Un-block"} this user`
      )
    ) {
      loader(true);
      ApiClient.put(`edit-profile`, {
        id: itm.id,
        isBlock: itm.isBlock ? false : true,
      }).then((res) => {
        if (res.success) {
          getData();
        }
        loader(false);
      });
    }
  };

  const view = (id) => {
    console?.log("heloooooo");
    history("/userDetail/" + id);
  };

  const edit = (id) => {
    let url = `/users/edit/${id}`;
    history(url);
  };

  const tabChange = (tab) => {
    setTab(tab);
  };

  const exportfun = async () => {
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${environment.api}api/export/user`,
      responseType: "blob",
      body: { token: token },
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Users.xlsx`;
    link.click();
  };

  const reset = () => {
    let filter = {
      status: "",
      subRole: "",
      page: 1,
    };
    setFilter({ ...filters, ...filter });
    getData({ ...filter });
    // dispatch(search_success(''))
  };

  const isAllow = (key = "") => {
    let permissions = user.role?.permissions[0];
    let value = permissions?.[key];
    if (user.role.id == environment.adminRoleId) value = true;
    // return value
    return true;
  };

  const refund = () => {
    handleRefund(refundId);
  };

  const handleRefund = (id) => {
    const payload = {
      retref: retref,
      mode: user?.mode ? "live" : "sandbox",
      merchant_id: userId,
    };
    setLoader(true);
    ApiClient.post(`refund`, payload).then((response) => {
      if (response.success) {
        setLoader(false);
        setShowRefundModal(false);
        toast.success(response.data);
        getData();
      } else {
        setLoader(false);
        setShowRefundModal(false);
      }
    });
  };

  const handleSendRefundRequest = (id) => {
    const payload = {
      transaction_id:id
    };
    setLoader(true);
    ApiClient.post(`refund-request`, payload).then((response) => {
      if (response.success) {
        toast.success(response.message);
        getData();
      } else {
        setLoader(false);
      }
    });
  };

  //------------------------------------function to decrypt the data --------------------------------------

  function decryptData(encryptedValue) {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, environment?.secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  const statuses = [
    { id: "Queued for Capture", name: "Queued for Capture" },
    { id: "Approval", name: "Approval" },
    { id: "captured", name: "Captured" },
  ];

   const customerStatus =[ 
    { id: "success", name: "Success" },
    { id: "failed", name: "Failed" },
   ]

  return (
    <>
      <Html
        colClick={colClick}
        exportfun={exportfun}
        isAllow={isAllow}
        tabChange={tabChange}
        tab={tab}
        reset={reset}
        roles={roles}
        view={view}
        edit={edit}
        ChangeRole={ChangeRole}
        changestatus={changestatus}
        openModal={openModal}
        pageChange={pageChange}
        addCol={addCol}
        deleteItem={deleteItem}
        exportCsv={exportCsv}
        uTableCols={uTableCols}
        removeCol={removeCol}
        filters={filters}
        tableCols={tableCols}
        loaging={loaging}
        data={data}
        total={total}
        statusChange={statusChange}
        blockunblock={blockunblock}
        history={history}
        refundId={refundId}
        setRefundid={setRefundid}
        ShowRefundModel={ShowRefundModal}
        setShowRefundModal={setShowRefundModal}
        refund={refund}
        user={user}
        retref={retref}
        setRetRef={setRetRef}
        decryptData={decryptData}
        statuses={statuses}
        clear={clear}
        handleSendRefundRequest={handleSendRefundRequest}
        customerStatus={customerStatus}
      />
    </>
  );
};

export default transactions;
