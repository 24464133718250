import React, { useEffect, useState } from "react";
import axios from "axios";
import ApiClient from "../../apimethods/apiClient";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const CardPaymentForm = () => {
    const user = useSelector(state => state.user)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const orderId = searchParams.get('order_id'); 
    const history = useNavigate() 
    const [formData, setFormData] = useState({
        order_id: orderId,
        transactionCurrencyCode: "USD",
        senderAccountNumber: "",
        exp_date: "",
        cvv: "",
    });

    // useEffect(() => {
    //     if (!localStorage.getItem('token')) {
    //         history(`/login?order_id=${orderId}`);
    //     }
    // }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "cvv" && !/^\d{0,4}$/.test(value)) {
            return;
        }

        if (name === "senderAccountNumber") {
            if (value.length <= 16 && /^\d+$/.test(value)) {
                setFormData({ ...formData, [name]: value });
            }
        } else if (name === "exp_date") {
            const sanitizedValue = value.replace(/[^\d/]/g, "");
            if (sanitizedValue.length <= 7) {
                let formattedValue = sanitizedValue;
                if (sanitizedValue.length === 3 && sanitizedValue.charAt(2) !== "/") {
                    formattedValue =
                        sanitizedValue.slice(0, 2) + "/" + sanitizedValue.slice(2);
                }
                setFormData({ ...formData, [name]: formattedValue });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Validate card details (you can replace this with your own validation logic)
      if (formData.senderAccountNumber.length !== 16) {
        alert("Invalid card number. Please enter a 16-digit card number.");
        return;
      }
      // Split expiry date into month and year
      const [exp_month, exp_year] = formData.exp_date.split("/");
      if (
        exp_month.length !== 2 ||
        exp_year.length !== 4 ||
        isNaN(Number(exp_month)) ||
        isNaN(Number(exp_year))
      ) {
        alert("Invalid expiry date. Please enter in the format MM/YYYY.");
        return;
      }

      const payload = {
        ...formData,
        exp_month: exp_month,
        exp_year: exp_year,
      };
      delete payload?.exp_date;

            ApiClient.post("/open/order/pay-now", payload).then((res) => {
                if (res?.success) {
                    toast.success(res?.message)
                    history('/')
                }
            });
        } catch (error) {
            console.error("Error:", error)
  };
}

  return (
    <div className="mt-5">
      <div className="paywitdh">
        <div className="paymentcard">
          <h3 className="payheader px-4">Your Payment Details</h3>
          <div className="container">
            <div className="row pr-md-3">
              <div className="col-sm-6 pl-0 pr-md-3 pr-0">
                <div className="d-flex justify-content-center align-items-center h-100 graybg">
                  {" "}
                  <img
                    src="/assets/img/logo-dark.png"
                    className="paymentlogo"
                  />
                </div>
              </div>
              <div className="col-sm-6 my-3 px-3 ">
                <div className="row">
                  {" "}
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="number">Card Number</label>
                          <input
                            type="text" // Changed type to text
                            id="number"
                            name="senderAccountNumber"
                            className="form-control"
                            value={formData.senderAccountNumber}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="exp_date">
                            Expiry Date (MM/YYYY)
                          </label>
                          <input
                            type="text"
                            id="exp_date"
                            name="exp_date"
                            className="form-control"
                            value={formData.exp_date}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        <div className="form-group">
                          <label htmlFor="cvv">CVV</label>
                          <input
                            type="text"
                            id="cvv"
                            name="cvv"
                            className="form-control"
                            value={formData.cvv}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="paybutton d-flex justify-content-center">
                      {" "}
                      <button type="submit" className="btn dark-btn">
                        Pay Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPaymentForm;
