/*
 * @file: user.js
 * @description: Reducers and actions for store/manipulate user's  data
 * @date: 28.11.2019
 * @author: Poonam
 */

/******** Reducers ********/

const initialState = {
  loggedIn: false,
  notifications: [],
  mode: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, ...{ loggedIn: true }, ...action.data, mode: true };
    case 'LOG_OUT':
      return initialState;
    case 'TOGGLE_MODE':
      return {
        ...state,
        mode: true,
      };
    default:
      return { ...state, mode: true };
  }
}
