import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../components/global/layout";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import loader from "../../components/loader/loader";
import ApiClient from "../../apimethods/apiClient"; 
import { DatePicker } from "antd";
import BarGraph from "../../components/graphs/bargraph";
import SelectDropdown from "../../components/common/selectdropdown";
import SalesAreaChart from "../../components/graphs/salesgraph";

const Dashboard = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState();
  const user = useSelector((state) => state.user);
  const [dates, setDates] = useState([null, null]);
  const [data, setData] = useState();
  const [refundAgree, setRefundAgree] = useState("monthly");
  const [salesAgree, setsalesAgree] = useState("monthly");
  const [refunddata, setRefundData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const { RangePicker } = DatePicker;
  useEffect(() => {
    if (user.loggedIn) {
      if (user?.role?.name != "Customer") {
        getMerchantAnalytics();
      } else {
        getCutsomerAnalytics();
      }
    }
  }, [user?.mode]);

  const list = [
    { id: "weekly", name: "Weekly" },
    { id: "monthly", name: "Monthly" },
    { id: "yearly", name: "Yearly" },
  ];

  useEffect(() => {
    if (user?.role?.name == "Merchant") {
      getRefundGraphdata();
    }
  }, [refundAgree]);

  useEffect(() => {
    if (user?.role?.name == "Merchant") {
      getsalesGraphdata();
    }
  }, [salesAgree]);

  const getRefundGraphdata = () => {
    loader(true);
    ApiClient.get(`refund/graph?mode=sandbox&granularity=${refundAgree}&merchant_id=${
        user?.id || user?._id}`).then(
      (res) => {
        if (res?.success) {
          loader(false);
          setRefundData(res?.data);
        }
      }
    );
  };

  const getsalesGraphdata = () => {
    loader(true);
    ApiClient.get(
      `sale/graph?mode=sandbox&granularity=${salesAgree}&merchant_id=${
        user?.id || user?._id
      }`
    ).then((res) => {
      if (res?.success) {
        loader(false);
        setSalesData(res?.data);
      }
    });
  };

  const getMerchantAnalytics = (updatedFilters) => {
    loader(true);
    let filter = {
      ...updatedFilters,
      mode: user?.mode ? "live" : "sandbox",
      type: "cart",
      merchant_id: user?.id || user?._id,
    };
    ApiClient.get("analytics", filter).then((res) => {
      if (res?.success) {
        loader(false);
        setData(res?.data);
      }
    });
  };

  const getCutsomerAnalytics = (updatedFilters) => {
    loader(true);
    let filter = {
      ...updatedFilters,
      mode: user?.mode ? "live" : "sandbox",
      type: "cart",
    };
    ApiClient.get("customer/analytics", filter).then((res) => {
      if (res?.success) {
        loader(false);
        setData(res?.data);
      }
    });
  };

  const onChange = (dates, dateStrings) => {
    setDates(dateStrings);
    const updatedFilters = {
      start_date: dateStrings[0],
      end_date: dateStrings[1],
    };
    setFilters(updatedFilters);
    getMerchantAnalytics(updatedFilters);
  };
  return (
    <>
      <Layout>
        <div className="row d-flex align-items-center">
          <div className="col-md-9 head_cls">
            <h2 className="mb-1 mainHeading_cls">
              {" "}
              <span className="dsh_hedding">Hi</span>
              {user && user.fullName}
            </h2>
            <p className="paraCls">
              Here’s what’s going on with your team Lux Financial
            </p>
          </div>
          {user?.role?.name != "Customer" ? (
            <div className="col-md-3 text-right d-flex justify-content-end">
              <RangePicker format="YYYY-MM-DD" onChange={onChange} />
            </div>
          ) : (
            ""
          )}
          <div className="cards_sales">
            {user?.role?.name != "Customer" ? (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
                  <div className="sales_section">
                    <div
                      className="main_sales"
                      onClick={(e) => navigate(`/transaction`)}
                    >
                      <div className="sales_headings">
                        <div>
                          <p className="sub_heading">Total Sales</p>
                        </div>
                        <div>
                          <img
                            src="/assets/img/img/bag.png"
                            className="wallets_img"
                          />
                        </div>
                      </div>
                      <div className="sales_icons">
                        <h3 className="Amount_sales">
                          ${" "}
                          {parseFloat(data?.totalTransactionAmount).toFixed(
                            2
                          ) || "--"}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3"
                  onClick={(e) => navigate(`/transaction`)}
                >
                  <div className="sales_section">
                    <div className="main_sales">
                      <div className="sales_headings">
                        <div>
                          <p className="sub_heading">Net Sales</p>
                        </div>
                        <div>
                          <img
                            src="/assets/img/img/wallet.png"
                            className="wallets_img"
                          />
                        </div>
                      </div>
                      <div className="sales_icons">
                        <h3 className="Amount_sales">
                          ${" "}
                          {parseFloat(data?.receivedamount).toFixed(2) || "--"}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3 pointer"
                  onClick={(e) => navigate(`/transaction`)}
                >
                  <div className="sales_section">
                    <div className="main_sales">
                      <div className="sales_headings">
                        <div>
                          <p className="sub_heading">Transaction Volume:</p>
                        </div>
                        <div>
                          <img
                            src="/assets/img/img/bag.png"
                            className="wallets_img"
                          />
                        </div>
                      </div>
                      <div className="sales_icons">
                        <h3 className="Amount_sales">
                          {data?.transaction_volume || "--"}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3 pointer"
                  onClick={(e) => navigate(`/transaction`)}
                >
                  <div className="sales_section">
                    <div className="main_sales">
                      <div className="sales_headings">
                        <div>
                          <p className="sub_heading">Transaction Value:</p>
                        </div>
                        <div>
                          <img
                            src="/assets/img/img/bag.png"
                            className="wallets_img"
                          />
                        </div>
                      </div>
                      <div className="sales_icons">
                        <h3 className="Amount_sales">
                          $ {data?.totalTransactionAmount || "00"}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3 pointer"
                  onClick={(e) => navigate(`/transaction`)}
                >
                  <div className="sales_section">
                    <div className="main_sales">
                      <div className="sales_headings">
                        <div>
                          <p className="sub_heading">No. of Refunds:</p>
                        </div>
                        <div>
                          <img
                            src="/assets/img/img/bag.png"
                            className="wallets_img"
                          />
                        </div>
                      </div>
                      <div className="sales_icons">
                        <h3 className="Amount_sales">
                          {data?.transaction_refunds ||
                            "--"}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3 pointer"
                  onClick={(e) => navigate(`/transaction`)}
                >
                  <div className="sales_section">
                    <div className="main_sales">
                      <div className="sales_headings">
                        <div>
                          <p className="sub_heading">Refunded Amount:</p>
                        </div>
                        <div>
                          <img
                            src="/assets/img/img/bag.png"
                            className="wallets_img"
                          />
                        </div>
                      </div>
                      <div className="sales_icons">
                        <h3 className="Amount_sales">
                          ${" "}
                          {parseFloat(data?.transaction_refunds_amount).toFixed(
                            2
                          ) || "--"}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
                  <div className="sales_section">
                    <div
                      className="main_sales"
                      onClick={(e) => navigate(`/transaction`)}
                    >
                      <div className="sales_headings">
                        <div>
                          <p className="sub_heading">Total Purchase</p>
                        </div>
                        <div>
                          <img
                            src="/assets/img/img/bag.png"
                            className="wallets_img"
                          />
                        </div>
                      </div>
                      <div className="sales_icons">
                        <h3 className="Amount_sales">
                          ${" "}
                          {parseFloat(data?.totalTransactionAmount).toFixed(
                            2
                          ) || "--"}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3 pointer"
                  onClick={(e) => navigate(`/transaction`)}
                >
                  <div className="sales_section">
                    <div className="main_sales">
                      <div className="sales_headings">
                        <div>
                          <p className="sub_heading">Transaction Volume:</p>
                        </div>
                        <div>
                          <img
                            src="/assets/img/img/bag.png"
                            className="wallets_img"
                          />
                        </div>
                      </div>
                      <div className="sales_icons">
                        <h3 className="Amount_sales">
                          {data?.transaction_volume || "--"}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3 pointer"
                  onClick={(e) => navigate(`/transaction`)}
                >
                  <div className="sales_section">
                    <div className="main_sales">
                      <div className="sales_headings">
                        <div>
                          <p className="sub_heading">No. of Refunds:</p>
                        </div>
                        <div>
                          <img
                            src="/assets/img/img/bag.png"
                            className="wallets_img"
                          />
                        </div>
                      </div>
                      <div className="sales_icons">
                        <h3 className="Amount_sales">
                          {data?.transaction_refunds || "0"}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3 pointer"
                  onClick={(e) => navigate(`/transaction`)}
                >
                  <div className="sales_section">
                    <div className="main_sales">
                      <div className="sales_headings">
                        <div>
                          <p className="sub_heading">Refunded Amount:</p>
                        </div>
                        <div>
                          <img
                            src="/assets/img/img/bag.png"
                            className="wallets_img"
                          />
                        </div>
                      </div>
                      <div className="sales_icons">
                        <h3 className="Amount_sales">
                          ${" "}
                          {parseFloat(data?.transaction_refunds_amount).toFixed(
                            2
                          ) || "--"}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* graph Design */}
            {user?.role?.name == "Merchant" ? 
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center mt-3 justify-content-between">
                  <h5 className="sales mb-0  ">Sales </h5>
                  <div className="">
                    <SelectDropdown
                      className="dashbopard-btn"
                      id="statusDropdown"
                      displayValue="name"
                      theme={"search"}
                      intialValue={salesAgree}
                      isClearable={false}
                      result={(e) => {
                        setsalesAgree(e.value);
                      }}
                      options={list}
                    />
                  </div>
                </div>
                <div className="chatr_ones border border-gray-200 p-6 mt-2 rounded-lg">
                  <SalesAreaChart data={salesData} period={salesAgree} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex align-items-center mt-3 justify-content-between">
                  <h5 className="sales mb-0  ">Refunds</h5>
                  <div className="">
                    <SelectDropdown
                      className="dashboard-btn"
                      id="statusDropdown"
                      displayValue="name"
                      theme={"search"}
                      intialValue={refundAgree}
                      isClearable={false}
                      result={(e) => {
                        setRefundAgree(e.value);
                      }}
                      options={list}
                    />
                  </div>
                </div>
                <div className="chatr_ones border border-gray-200 p-6 mt-2 rounded-lg">
                  <BarGraph data={refunddata} period={refundAgree} />
                </div>
              </div>
            </div>
             : ""}
          </div>
        </div>
      </Layout>
      <div class="onload-modal">
        {/* Your component content */}

        {/* {isModalOpen && (
        <div className="modal1">

          <div  className="bank-modal">
            <div className="position-relative">
              <span className="close-icon" onClick={() => setIsModalOpen(false)}>
                &times;
              </span>
            </div>
            {(user?.role_name != 'Customer' || 'Guest' && user?.active_accounts <= 0) && <div className="bg-white-modal">
              <img src="assets/img/logo.png" />
              <button onClick={() => {
                // open()
                handleClick()
                setIsModalOpen(false)
              }} className="btn dark-btn w-100 bank-modal-btn">Connect a Bank Account</button>
              </div>}
            
          </div>
        </div>


      )} */}
      </div>
    </>
  );
};

export default Dashboard;
