import React from "react";
import { useSelector } from "react-redux";
import methodModel from "../../validations/Validations";

const Html = ({ ListItemLink, user, urlAllow }) => {
 
  return (

  <>
   
    <div className="text-center py-3 side-profile">
      <img
        alt="image"
        src={methodModel.userImg(user.image)}
        width={60}
        height={60}
        className="rounded-circle drop_innerimg mb-2"
      />
      <div className="ml-1 ">
        <b className="mt-2 user_name">
          {user.fullName}
        </b>
        <p className="dropgray mb-0 text-capitalize mt-1 customer_bx">
          {user.role?.name}
        </p>
      </div>
    </div>

    

    
  
  
  
    <ul className="nav flex-column mt-3" component="siderbar">
      {urlAllow("readDashboard") ? (
        <ListItemLink to="/dashboard">
          <i class="fa fa-th-large" aria-hidden="true"></i>

          <span class="side_head">Dashboard</span>
        </ListItemLink>
      ) : null}

      {urlAllow("customers") &&
      user?.role_name != "Customer" &&
      user?.role_name != "Guest" ? (
        <ListItemLink to="/AllCustomers">
          <i class="fa fa-users" aria-hidden="true"></i>

          <span class="side_head">Customers</span>
        </ListItemLink>
      ) : null}

      {urlAllow("customers") &&
      user?.role_name != "Customer" &&
      user?.role_name != "Guest" ? (
        <ListItemLink to="/addcredential">
          <i class="fa fa-id-card" aria-hidden="true"></i>
          <span class="side_head">Cardpointe Credentials</span>
        </ListItemLink>
      ) : null}
      {/* {urlAllow("customers") &&
      user?.role_name != "Customer" &&
      user?.role_name != "Guest" ? (
        <ListItemLink to="/accounts">
          <i class="fa fa-user-circle" aria-hidden="true"></i>
          <span class="side_head">Accounts</span>
        </ListItemLink>
      ) : null} */}
 
      {urlAllow("customers") &&
      user?.role_name != "Customer" &&
      user?.role_name != "Guest" ? (
        <ListItemLink to="/product">
          {/* <i class="fa fa-database" aria-hidden="true"></i> */}
          <i class="fa fa-cube"></i>
          <span class="side_head">Products</span>
        </ListItemLink>
      ) : null}

   
      {urlAllow("customers") &&
      user?.role_name != "Customer" &&
      user?.role_name != "Guest" ? (
        <ListItemLink to="/funds">
          {/* <i class="fa fa-database" aria-hidden="true"></i> */}
          <i class="fa fa-usd"></i>
          <span class="side_head">Funds</span>
        </ListItemLink>
      ) : null}
      <ListItemLink to="/orders">
        {/* <i class="fa fa-database" aria-hidden="true"></i> */}
        <i class="fa fa-file"></i>
        <span class="side_head">Orders</span>
      </ListItemLink>

      <ListItemLink to="/transaction">
        <i class="fa fa-random" aria-hidden="true"></i>
        <span class="side_head">Transactions</span>
      </ListItemLink>
    </ul></>
  );
};

export default Html;
